import React from 'react';
import Rating from '@mui/material/Rating';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

const StyledRatingComponent = styled(Rating)({
    '& .MuiRating-icon': {
        color: '#FF9533',
    },
    '& .MuiRating-iconFilled': {
        color: '#FF9533',

    },
    '& .MuiRating-iconHover': {
        color: '#FF9533',
    },
    '& .MuiRating-iconEmpty': {
        color: '#FF9533',
    },
});

const StyledRating = ({ rating, totalRatings }) => {
    return (
        <Box display="flex" alignItems="center">
            <Box
                sx={{
                    fontSize: '14px',
                    fontWeight: 500,
                    lineHeight: '21.7px',
                    textAlign: 'left',
                    textDecorationSkipInk: 'none',
                    color: '#1F1F2A',
                }}
                mr={0.5}>{rating}</Box>
            <StyledRatingComponent
                precision={0.5}
                size="small"
                value={rating} readOnly />
            <Box
                sx={{
                    fontSize: '14px',
                    fontWeight: 500,
                    lineHeight: '21.7px',
                    textAlign: 'left',
                    textDecorationSkipInk: 'none',
                    color: '#1F1F2A',
                }}
                ml={0.5}>({totalRatings})</Box>
        </Box>
    );
};

export default StyledRating;