import React, { useState, useMemo, useEffect } from 'react';
import { Card, CardContent, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { paths } from '../../data/constants.js';
import { useNavigate } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import ReusableMenuPets from '../Menus/ReusableThreeMenuPets.jsx';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { formatDateTime } from '../../utils/petUtils.js';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const useStyles = makeStyles((theme) => ({
  card: {
    position: "relative",
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: 'fit-content',
    // maxHeight: 200,
    padding: '16px',
    borderRadius: '12px !important',
    marginBottom: '16px',
    boxShadow: '0px 2px 35px 0px #00000015 !important',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    // space between
    justifyContent: 'space-between',
    // height: 25,
  },
  petName: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: '8px',
    gap: '6px',
    "&.MuiTypography-root": {
      // 16px
      fontSize: '1rem',
      fontWeight: 600,
      lineHeight: '24.8px',
      color: "#1F1F2A"
    },
  },
  content: {
    display: 'flex',
    // take all available space
    flexGrow: 1,
    flexDirection: 'column',
    alignItems: 'start',
    cursor: 'pointer',
    // maxHeight: 64,
    "&.MuiCardContent-root": {
      padding: "0px !important",
    },
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '6px',
    justifyContent: 'center',
  },
  chip: {
    height: 27,
    borderRadius: '6px !important',
    pointerEvents: 'none',
    "&.MuiChip-root": {
      maxWidth: 220,
      width: 'fit-content',
      padding: '6px 8px',
      fontSize: '0.75rem', // 12px
      fontWeight: 600,
      lineHeight: '14.63px',
    },
    "& .MuiChip-label": {
      padding: 0,
    },
  },
  moreIcon: {
    // fix to top right with 12px padding top and 8px padding right from card
    "&.MuiIconButton-root": {
      position: 'absolute',
      top: 12,
      right: 8,
      padding: 0,
    },

  },
  carousel: {
    display: 'flex',
    flexDirection: 'row',
    gap: '8px',
    alignItems: 'center',
    width: '100%',
    height: 'fit-content',
  },
  section: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: 'fit-content',
    marginBottom: '8px',
  },
  description: {
    "&.MuiTypography-root": {
      fontSize: '16px',
      fontWeight: 500,
      lineHeight: '24.8px',
      color: "#1F1F2A",
    },
  },
  dialogImage: {
    width: '100%',
    height: 'auto',
    // borderRadius: '8px',
    objectFit: 'contain',
  },
  innerDetails: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    "&.MuiTypography-root": {
      marginBottom: '8px',
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '17.07px',
      color: "#7D7D97",
    },
  },
  ownerImage: {
    width: 32,
    height: 32,
    borderRadius: '45px',
    border: "2px solid #FFFFFF",
    boxShadow: '0px 2px 12px 0px #00000026',
    objectFit: 'cover',
  },
  ownerName: {
    "&.MuiTypography-root": {
      fontSize: '16px',
      fontWeight: 500,
      lineHeight: '17.07px',
      color: "#1F1F2A",
    },
  },
}));

const UpcomingCard = ({ pet, reminder, handleDeleteEntry, handleUpdateEntry }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const reminderData = reminder;

  const [isMarkedAsDone, setIsMarkedAsDone] = useState(reminder.status === 'completed');
  const formattedDataTime = useMemo(() => {
    const { formattedTime, formattedDate } = formatDateTime(reminderData.time, reminderData.date);
    return `${formattedTime}, ${formattedDate}`;
  }, [reminderData.date, reminderData.time]);

  const formatLocation = (location) => {
    if (location.length > 40) {
      return location.substring(0, 40) + '...';
    }
    return location;
  };

  const handleEditEntry = () => {
    navigate(paths.petReminderEdit(reminder.pet, reminder.id));
  };

  const handleMarkAsDone = () => {
    if (isMarkedAsDone) {
      handleUpdateEntry(reminder.id, { ...reminder, status: 'pending' });
      return;
    }
    handleUpdateEntry(reminder.id, { ...reminder, status: 'completed' });
  };
  useEffect(() => {
    setIsMarkedAsDone(reminder.status === 'completed');
  }, [reminder.status]);

  const menuItems = [
    {
      label: isMarkedAsDone ? 'Mark as Pending' : 'Mark as Done',
      icon: <CheckCircleIcon />,
      action: () => { handleMarkAsDone() },
    },
    {
      label: 'Edit Reminder',
      icon: <BorderColorOutlinedIcon sx={{color: '#1F1F2A'}} />,
      action: () => { handleEditEntry() },
    },
    {
      label: 'Delete Reminder',
      icon: <DeleteOutlinedIcon />,
      action: () => { handleDeleteEntry(reminder.id) },
    },
  ];
  return (
    <Card fullWidth className={classes.card}>
      <CardContent className={classes.content}>

        {/* entry type as a chip */}
        <Typography className={classes.petName}>
          {reminderData.title} {isMarkedAsDone && <CheckCircleIcon style={{ color: '#03A1AB', height: 20 }} />}
        </Typography>

        <Typography className={classes.innerDetails}>
          <AccessTimeIcon fontSize="small" />
          {/* Date example: 00:00 PM, 22 November 2024 */}
          {formattedDataTime}
        </Typography>
        <Typography className={classes.innerDetails}>
          <LocationOnOutlinedIcon fontSize="small" />
          {formatLocation(reminderData.location)}
        </Typography>
        {pet?.name &&
          <div className={classes.innerDetails}>
          <img src={pet?.photo || `${process.env.PUBLIC_URL}/logo-gray.png`} alt='logo' className={classes.ownerImage} />
          <Typography className={classes.ownerName}>
            {pet?.name}
          </Typography>
        </div>
        }


        {/* Menu */}
        <IconButton
          className={classes.moreIcon}
          onClick={(e) => {
            e.stopPropagation(); // Prevent the click from propagating to the card
            // Add any functionality to open the menu here
          }}
        >
          <ReusableMenuPets pet={{ name: pet?.name }} menuItems={menuItems} />
        </IconButton>

      </CardContent>
    </Card>
  );
};

export default UpcomingCard;