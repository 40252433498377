import React from 'react';
import { Chip, Avatar, IconButton, Typography, Tabs, Tab, Box, Grid, useMediaQuery, useTheme } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import { paths } from '../../data/constants.js';

const useStyles = makeStyles((theme) => ({
    header: {
        padding: theme.spacing(2),
        display: 'flex',
        alignItems: 'center',
        borderBottom: `1px solid ${theme.palette.divider}`,
        backgroundColor: theme.palette.background.paper,
        width: '100dvw',
    },
    title: {
        "&.MuiTypography-root": {
            fontSize: '16px',
            fontWeight: 600,
            lineHeight: '24.38px',
        },
        [theme.breakpoints.down('sm')]: {
            flexGrow: 1,
            textAlign: 'center',
            alignItems: 'center',
        },
        padding: '14px 0',
    },
    icons: {
        '&.MuiIconButton-root': {
            color: "#1F1F2A",
            marginLeft: '12px',
        },
    },
    petHeaderContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        width: '100%',
        // padding: '0 16px',
    },
}));

const SimpleStickyHeader = ({ title, redirectTo }) => {
    const classes = useStyles();
    const navigate = useNavigate();

    const handleBackClick = () => {
        navigate(paths.pets, { state: { goToTab: redirectTo } });
    };
    return (
        <div
            className={classes.petHeaderContainer}
            style={{
                position: 'sticky',
                top: 0,
                zIndex: 100,
                backgroundColor: '#fff',
                boxShadow: '0px 2px 12px 0px #00000005',
            }}

        >
            <Grid container alignItems="center" className={classes.titleContainer}>
                {/* Back Arrow Icon on the Start */}
                <Grid item xs={2}>
                    <IconButton onClick={handleBackClick} className={classes.icons}>
                        <ArrowBackIcon />
                    </IconButton>
                </Grid>

                {/* Title in the Center */}
                <Grid item xs={8} container justifyContent="center">
                    <Typography p={"11.5px 0"} className={classes.title}>
                        {title}
                    </Typography>
                </Grid>

                {/* Spacer for Layout Balance */}
                <Grid item xs={2} />
            </Grid>
        </div >
    );
};

export default SimpleStickyHeader;