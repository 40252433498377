import React, { useEffect, useState } from 'react';
import SimpleStickyHeader from '../components/Header/simpleStickyHeader';
import BottomContactButtons from '../components/Buttons/BottomContactButtons';
import useVetStorage from '../managers/vetManager';
import { Link, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { makeStyles } from '@mui/styles';
import StyledRating from "../components/Rating/StyledRating.jsx";
import { Card, CardContent, CardHeader, Typography, Grid } from '@mui/material';
import { ReactComponent as PromoIcon } from "../components/Footer/footerIcons/dealsIcon.svg";
import { DEAL_FORWARD_WHATSAPP_MESSAGE } from "../data/constants";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const useStyles = makeStyles((theme) => ({
    swiperContainer: {
        height: '300px',
        [theme.breakpoints.up('sm')]: {
            height: '500px',
        },
    },
    mediaImage: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
    },
    contentContainer: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        marginBottom: '86px',
    },
    content: {
        padding: '20px',
    },
    card: {
        position: "relative",
        display: "flex",
        flexDirection: "column",
        width: "100%",
        borderRadius: "12px !important",
        boxShadow: "0px 2px 8px 0px #1E07511F !important",
        overflow: "hidden", // Ensures border-radius applies properly
    },
    cardContent: {
        padding: '16px !important',
    },
    detailContent: {
        padding: '16px !important',
        backgroundColor: "#F6F7FB",
        borderRadius: "12px",
        marginBottom: '20px',
    },
    detailContentTitle: {
        "&.MuiTypography-root": {
            marginBottom: '12px',
            fontSize: '12px',
            fontWeight: 600,
            lineHeight: '18.6px',
            color: "#7D7D97",
        },
    },

}));

const DealsCard = ({ deal }) => {
    const classes = useStyles();


    return (
        <Card className={classes.card} id={`deal-card-${deal.id}`}>
            <CardHeader
                sx={{
                    backgroundColor: "#F3F1FF",
                }}
                avatar={
                    <PromoIcon style={{
                        width: '41px',
                        height: '41px',
                        fill: "#7019FF"
                    }} />
                }
                title={
                    deal?.max_deal &&
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                        <Typography sx={{ fontSize: '12px', fontWeight: 600, lineHeight: '18.6px', color: "#7D7D97" }}>
                            UP TO
                        </Typography>
                        <Typography sx={{ fontSize: '18px', fontWeight: 600, lineHeight: '21.94px', color: "#7019FF" }}>
                            {deal.max_deal}
                        </Typography>
                    </div>
                }
            />
            <CardContent className={classes.cardContent}>
                <Grid container spacing={2}>
                    {deal.discounts && deal.discounts.map((discount, index) => (
                        <>
                            <Grid item xs={4}>
                                <Typography
                                    sx={{
                                        fontSize: '14px',
                                        fontWeight: 600,
                                        lineHeight: '21.7px',
                                        color: "#1F1F2A"
                                    }}
                                >
                                    {discount.value}
                                </Typography>
                            </Grid>
                            <Grid item xs={8}
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'end',
                                    paddingLeft: '0'
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontSize: '14px',
                                        fontWeight: 500,
                                        lineHeight: '21.7px',
                                        color: "#7D7D97",
                                        textAlign: 'end'
                                    }}
                                >
                                    {discount.name}
                                </Typography>
                            </Grid>
                        </>
                    ))}
                </Grid>
            </CardContent>
            <CardContent className={classes.cardContent} style={{ borderTop: '1px solid #E3E8FF' }}>
                <Typography
                    sx={{
                        fontSize: '12px',
                        fontWeight: 600,
                        lineHeight: '18.6px',
                        color: "#7D7D97",
                        marginBottom: '8px',
                    }}
                >
                    How do I use <span style={{ fontWeight: 700 }}>Tr<span style={{ color: "#7019FF" }}>ee</span>t</span> deals?
                </Typography>
                <Typography
                    sx={{
                        fontSize: '14px',
                        fontWeight: 500,
                        lineHeight: '21.7px',
                        color: "#1F1F2A",
                    }}
                >
                    Simply show your pet’s adorable profile to the vet or receptionist!
                </Typography>
            </CardContent>
        </Card>
    );
};

const GoogleRating = ({ deal }) => {
    return (
        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
            <StyledRating rating={deal?.google_reviews?.rating || ""} totalRatings={deal?.google_reviews?.number_of_reviews || "Not Available"} />
            <img src={`${process.env.PUBLIC_URL}/google-reviews.png`} alt="google" style={{ height: "32px" }} />
        </div>
    );
};

const daysOrder = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];

const getToday = () => {
    const todayIndex = new Date().getDay(); // Get today's index (0 = Sunday, 6 = Saturday)
    return daysOrder[todayIndex]; // Get today's name
};

const WorkingHours = ({ workingHours }) => {
    if (!workingHours) return null; // Handle missing data

    const today = getToday();

    // Find the index of today in the daysOrder array
    const todayIndex = daysOrder.indexOf(today);

    // Rearrange the daysOrder array so that today is at the beginning
    const reorderedDays = [...daysOrder.slice(todayIndex), ...daysOrder.slice(0, todayIndex)];

    // Convert the object into an array and sort to place today at the top
    const sortedHours = Object.entries(workingHours).sort(([day1], [day2]) => {
        return reorderedDays.indexOf(day1) - reorderedDays.indexOf(day2);
    });

    return (
        <div>
            <Grid container spacing={1}>
                {sortedHours.map(([day, hours], index) => {
                    const isToday = day === today;
                    const isOpen = hours.toLowerCase() !== "closed";

                    return (
                        <Grid container item xs={12} key={day} sx={{ fontWeight: isToday ? 700 : 500 }}>
                            {/* Day Name */}
                            <Grid item xs={4}>
                                <Typography
                                    sx={{
                                        fontSize: "14px",
                                        fontWeight: isToday ? 700 : 500,
                                        lineHeight: "21.7px",
                                        color: "#1F1F2A",
                                    }}
                                >
                                    {day.charAt(0).toUpperCase() + day.slice(1)}
                                </Typography>
                            </Grid>

                            {/* Hours */}
                            <Grid item xs={6}>
                                <Typography sx={{
                                    fontSize: "14px",
                                    fontWeight: isToday ? 700 : 500,
                                    lineHeight: "21.7px",
                                    color: "#1F1F2A",
                                }}>
                                    {hours}
                                </Typography>
                            </Grid>

                            {/* Open/Closed Status */}
                            {isToday && (
                                <Grid item xs={2}>
                                    <Typography
                                        sx={{
                                            fontSize: "14px",
                                            fontWeight: isToday ? 600 : 500,
                                            color: isOpen ? "#03A1AB" : "red",
                                            lineHeight: "21.7px",
                                        }}
                                    >
                                        {isOpen ? "Open" : "Closed"}
                                    </Typography>
                                </Grid>
                            )}
                        </Grid>
                    );
                })}
            </Grid>
        </div>
    );
};

const ServicesList = ({ services }) => {
    if (!services) return null; // Handle missing data

    const formatCurrency = (amount) => {
        // AED with 2 decimal points
        // TODO: Add currency symbol based on user's location
        return `AED ${parseFloat(amount).toFixed(2)}`;
    };

    return (
        <div>
            <Grid container spacing={1}>
                {services.map((service, index) => (
                    <Grid container item xs={12} key={service.key} justifyContent={"space-between"} alignItems={"center"}>
                        {/* Service Name */}
                        <Grid item xs={8}>
                            <Typography
                                sx={{
                                    fontSize: "14px",
                                    fontWeight: 500,
                                    lineHeight: "21.7px",
                                    color: "#1F1F2A",
                                }}
                            >
                                {service.value}
                            </Typography>

                        </Grid>
                        {/* Service Amount */}
                        {service.amount &&
                            <Grid item xs={4}>
                                <Typography
                                    sx={{
                                        fontSize: "14px",
                                        fontWeight: 600,
                                        lineHeight: "21.7px",
                                        color: "#1F1F2A",
                                        textAlign: "end"
                                    }}
                                >
                                    {formatCurrency(service.amount)}
                                </Typography>
                            </Grid>
                        }
                    </Grid>
                ))}
            </Grid>
        </div>
    );
};



const PetDealProfile = () => {
    const classes = useStyles();
    const {
        getVetDealsByClinic
    } = useVetStorage();

    //get clinic id from params
    const { clinicId } = useParams();

    const [deal, setDeal] = useState({});

    const images = deal?.images || [];

    useEffect(() => {
        const fetchDeal = async () => {
            const deal = await getVetDealsByClinic(clinicId);
            console.log("Deal by clinic", deal);
            setDeal(deal);
        };
        if (clinicId) fetchDeal();
        else toast.error("Invalid clinic id");
    }, []);

    return (
        <div className={classes.contentContainer}>
            <SimpleStickyHeader title={deal.name} redirectTo={"deals"} />
            {/* Image Carousel */}
            {images.length > 0 && (
                <div className={classes.swiperContainer}>
                    <Swiper
                        loop
                        className={classes.swiperContainer}
                    >
                        {images.map((src, index) => (
                            <SwiperSlide key={index}>
                                <img src={src} alt={`slide-${index}`} className={classes.mediaImage} loading="lazy" />
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
            )}
            <div className={classes.content}>
                <DealsCard deal={deal} />

                {/* Divider */}
                <div style={{ padding: "20px 0" }}>
                    <hr style={{ border: "1px solid var(--Mid-Blue, #E3E8FF)", margin: 0 }} />
                </div>
                <div className={classes.detailContent}>
                    <GoogleRating deal={deal} />
                </div>
                <div className={classes.detailContent}>
                    <Typography
                        className={classes.detailContentTitle}
                    >
                        ADDRESS
                    </Typography>
                    <Typography
                        sx={{
                            fontSize: '14px',
                            fontWeight: 500,
                            lineHeight: '21.7px',
                            color: "#1F1F2A",
                            marginBottom: '12px',
                        }}
                    >
                        {deal?.address || "Loading..."}
                    </Typography>
                    <Link
                        to={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(deal?.address)}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ color: "#1F1F2A", textDecoration: "none" }}
                    >
                        <Typography
                            sx={{
                                fontSize: '14px',
                                fontWeight: 600,
                                lineHeight: '21.7px',
                                color: "#7019FF",
                                display: 'flex',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                            }}
                        >
                            {"View on Google Maps "} <ChevronRightIcon />
                        </Typography>
                    </Link>
                </div>
                <div className={classes.detailContent}>
                    <Typography
                        className={classes.detailContentTitle}
                    >
                        HOURS
                    </Typography>
                    <WorkingHours workingHours={deal?.working_hours} />
                </div>
                <div className={classes.detailContent}>
                    <Typography
                        className={classes.detailContentTitle}
                    >
                        SERVICES
                    </Typography>
                    <ServicesList services={deal?.services} />
                </div>
            </div>

            <BottomContactButtons item={deal} whatsappMessage={DEAL_FORWARD_WHATSAPP_MESSAGE} />
        </div>
    );
};

export default PetDealProfile;