import React, { useEffect } from 'react';
import Typography from '@mui/material/Typography';
import Grid2 from '@mui/material/Grid2';
import { makeStyles } from '@mui/styles';
import { Tabs, Tab } from '@mui/material';
import useDiaryStorage from '../../managers/diaryManager';
import DiaryCard from '../Medical/DiaryCard';
import { diaryEntryTypes } from '../../data/constants';

const useStyles = makeStyles((theme) => ({
    formContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: '100%',
    },
    formItem: {
        marginBottom: '16px',
    },
    iconContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        width: '100%',
        padding: '8px',
        gap: '8px',
    },
    iconText: {
        fontSize: '14px',
        fontWeight: 600,
        color: "#1F1F2A",
        lineHeight: '21.7px',
    },
    detailSection: {
        marginBottom: '24px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: '100%',

    },
    detailSectionTitle: {
        marginBottom: '24px',
    },
    detailTitle: {
        "&.MuiTypography-root": {
            fontSize: '18px',
            fontWeight: 600,
            lineHeight: '21.94px',
            textAlign: 'left',
            color: '#1F1F2A',
        },
    },
    newPetButtonWrapper: {
        // center the button at the bottom of the screen
        display: 'flex',
        position: 'fixed',
        bottom: '0dvh',
        left: '50%',
        transform: 'translateX(-50%)',
        zIndex: 1000,
        padding: '16px 24px',
        width: "fit-content",
        justifyContent: "center",
        alignItems: 'center',

    },
    contentContainer: {
        display: 'flex',
        width: '100%',
        minHeight: '60vh',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        marginBottom: '16px',
        "& .MuiGrid-root": {
            paddingTop: '16px',
            marginBottom: 'auto',
        },
    },
    noPetsContainer: {
        // absolute center
        // position: 'absolute',
        // top: '50%',
        // left: '50%',
        // transform: 'translate(-50%, -50%)',
        display: 'flex',
        // take all available space
        width: '100%',
        height: '100%',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#7D7D97',

    },
}));


const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            style={{ width: '100%' }}
            {...other}
        >
            {value === index && (
                <Grid2 container spacing={2} sx={{
                    width: '100%',
                }} alignContent={'center'} flexDirection={'column'}>
                    {children}
                </Grid2>
            )}
        </div>
    );
}

const DefaultEmptyDiary = () => {
    const classes = useStyles();
    return (
        <div className={classes.noPetsContainer}>
            <img src={`${process.env.PUBLIC_URL}/logo-gray.svg`} alt='logo' style={{
                marginBottom: '20px',
            }} />
            <Typography>No entries yet</Typography>
        </div>
    );
}

const PetDiary = ({ pet, profileId, isSharing }) => {
    const classes = useStyles();
    const [selectedTab, setSelectedTab] = React.useState(0);
    const [currentDiaryEntries, setCurrentDiaryEntries] = React.useState({});
    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
    }

    // pet id matches the medical history id
    const {
        getItemById: getDiaryItemByEntryId,
        deleteItem: deleteDiaryItem,
        fetchData
    } = useDiaryStorage('diary', profileId);

    const handleDeleteEntry = (entryId) => {
        const entry = getDiaryItemByEntryId(entryId);
        if (entry) {
            deleteDiaryItem(entryId).then((isDeleted) => {
                if (isDeleted) {
                    setCurrentDiaryEntries((prevEntries) => {
                        const newEntries = { ...prevEntries };
                        delete newEntries[entryId];
                        return newEntries;
                    });
                }
            });
        }
    }

    useEffect(() => {
        (async () => {
            const diaryEntries = await fetchData(pet?.id);
            setCurrentDiaryEntries(diaryEntries);
        })();
    }, [pet]);

    return (
        <div className={classes.formContainer}>
            <div className={classes.detailSection}>
                <div className={classes.detailSectionTitle}>
                    <Typography align="left" className={classes.detailTitle}>
                        Diary entries
                    </Typography>
                </div>

                <Tabs value={selectedTab} onChange={handleTabChange} aria-label="Diary tabs"
                    // no indicator for the tabs
                    indicatorColor={'none'}
                    orientation='horizontal'
                    variant='scrollable'
                    textColor="#1F1F2A"
                    // allowScrollButtonsMobile
                    scrollButtons='false'
                    // Change the box shadow of the tabs on selection
                    sx={{
                        "& .Mui-selected": {
                            boxShadow: '0px 2px 7.1px 0px #00000026',
                            borderRadius: '8px ',
                            margin: '6px',
                        },
                    }}
                >
                    {['All', ...Object.values(diaryEntryTypes)].map((title, index) => (
                        <Tab key={index} label={title} />
                    ))}
                </Tabs>
            </div>

            {/* map panels */}

            {[['all', 'All'], ...Object.entries(diaryEntryTypes)].map(([key, title], index) => (
                <TabPanel key={index} value={selectedTab} index={index}>
                    {(() => {
                        const hasEntries = Object.keys(currentDiaryEntries).length > 0 &&
                            Object.values(currentDiaryEntries).some(
                                (entry) => title === 'All' || entry.category === key
                            );

                        return (
                            <div
                                className={classes.contentContainer}
                                style={{
                                    justifyContent: hasEntries ? 'start' : 'center',
                                }}
                            >
                                {hasEntries ? (
                                    Object.values(currentDiaryEntries).sort(
                                        // sort by id descending to show the latest entries first
                                        // TODO - sort by creation date 
                                        (a, b) => b.id - a.id
                                    ).map((entry, index) => {
                                        if (title === 'All' || entry.category === key) {
                                            return (
                                                <DiaryCard
                                                    key={index}
                                                    diaryEntries={entry}
                                                    handleDeleteEntry={handleDeleteEntry}
                                                    isSharing={isSharing}
                                                />
                                            );
                                        }
                                        return null; // Return null for unmatched entries
                                    })
                                ) : (
                                    <DefaultEmptyDiary />
                                )}
                            </div>
                        );
                    })()}
                </TabPanel>

            ))}


        </div>
    );
};

export default PetDiary;