import React from "react";
import { BottomNavigation, BottomNavigationAction, Paper, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ReactComponent as PetsIcon } from "./footerIcons/dogHead.svg";
import { ReactComponent as PromoIcon } from "./footerIcons/dealsIcon.svg";
import PersonOutlineRoundedIcon from '@mui/icons-material/PersonOutlineRounded';
import { ReactComponent as EventIcon } from "./footerIcons/eventIcon.svg";
const useStyles = makeStyles((theme) => ({
    footerNav: {
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0,
        width: "100%",
        height: 56,
        zIndex: 1001,
        '&.MuiPaper-root': {
            backgroundColor: theme.palette.background.paper,
            boxShadow: "0 -2px 5px rgba(0,0,0,0.1)",
        },
    },
    indicator: {
        position: "absolute",
        top: 0,
        left: 0,
        width: "25%", // Since we have 4 tabs
        height: 2,
        backgroundColor: theme.palette.primary.main,
        transition: "transform 0.3s ease-in-out",
    },
    action: {
        color: "#7D7D97 !important", // Custom inactive color
        "& .MuiBottomNavigationAction-label": {
            fontSize: "10px !important",
            fontWeight: 700,
            lineHeight: "15.5px"
        },
        "&.Mui-selected": {
            color: `${theme.palette.primary.main} !important`, // Active tab color
        },
    },
    icon: {
        width: 24,
        height: 24,
        fill: "#7D7D97", // Default icon color
        marginBottom: "4px"
    },
    activeIcon: {
        width: 24,
        height: 24,
        fill: theme.palette.primary.main, // Change fill color dynamically
        marginBottom: "4px"

    },
}));

const PetsFooterNavBar = ({ tabIndex, setTabIndex }) => {
    const classes = useStyles();

    return (
        <Paper elevation={3} className={classes.footerNav}>
            <Box
                className={classes.indicator}
                style={{ transform: `translateX(${tabIndex * 100}%)` }}
            />

            <BottomNavigation

                showLabels
                value={tabIndex}
                onChange={(event, newValue) => setTabIndex(newValue)}
            >
                <BottomNavigationAction
                    className={classes.action}
                    label="Pets"
                    icon={<PetsIcon className={tabIndex === 0 ? classes.activeIcon : classes.icon} />}
                />
                <BottomNavigationAction
                    className={classes.action}
                    label="Reminders"
                    icon={<EventIcon className={tabIndex === 1 ? classes.activeIcon : classes.icon} />}
                />
                <BottomNavigationAction
                    className={classes.action}
                    label="Deals"
                    icon={<PromoIcon className={tabIndex === 2 ? classes.activeIcon : classes.icon} />}
                />
                <BottomNavigationAction
                    className={classes.action}
                    label="Parent"
                    icon={<PersonOutlineRoundedIcon className={tabIndex === 3 ? classes.activeIcon : classes.icon} />}
                />
            </BottomNavigation>
        </Paper>
    );
};

export default PetsFooterNavBar;