import { styled } from '@mui/material/styles';
import React from 'react';
import { useDropzone } from 'react-dropzone';
import { toast } from 'react-toastify';
import ApiClient from '../../utils/client';

const DropzoneContainer = styled('div')(({ theme, isEditing }) => ({
  width: '120px',
  height: '120px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  margin: '0 auto',
  justifyContent: 'center',
  border: '2px solid #FFFFFF',
  borderRadius: '12px',
  background: 'var(--Light-Grey, #F6F7FB)',
  cursor: isEditing ? 'pointer' : 'not-allowed', // Conditionally set cursor
  marginBottom: '16px',
  boxShadow: '0px 2px 12px 0px #00000026',
  '& img': {
    width: '21px',
    height: '19px',
  },
}));

const DropzoneImage = ({ formik, isEditing = false }) => {
  const uploadFile = async (file) => {
    const api = new ApiClient();

    const fileExtension = file.name.split('.').pop();
    if (!fileExtension) {
      toast.error('Invalid file extension');
      return;
    }

    const params = new URLSearchParams();
    params.append('file_name', fileExtension);
    params.append('file_type', encodeURIComponent(file.type));

    const response = await api.get(`cms/image/presigned-url?${params}`);

    if (!response) {
      toast.error('Failed to get image response');
      return;
    }

    const { presigned_url: presignedUrl, file_url: s3Url } = response;

    const uploadResponse = await fetch(presignedUrl, {
      method: 'PUT',
      body: file,
      headers: {
        'Content-Type': file.type,
      },
    });

    if (uploadResponse.ok) return s3Url;

    throw new Error('Something went wrong');
  };

  // Conditionally handle onDrop based on `isEditing`
  const { getRootProps, getInputProps } = useDropzone({
    // allow the most common image formats only
    accept: {
      'image/*': [],
    },
    maxFiles: 1,
    disabled: !isEditing, // Disable dropzone if not editing
    onDrop: async (acceptedFiles) => {
      if (!isEditing) return; // Prevent function if not editing
      if (acceptedFiles.length === 0) {
        toast.error('Please upload an image file');
        return;
      }

      const imageFile = acceptedFiles[0];

      try {
        // Verify file is valid
        if (!imageFile.size) {
          toast.error('Invalid file size');
          return;
        }

        const s3Url = await uploadFile(imageFile);

        formik.setFieldValue('photo', s3Url); // Set the formik field value
      } catch (error) {
        toast.error(error.message, {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    },
  });

  return (
    <DropzoneContainer {...getRootProps()} isEditing={isEditing}>
      <input {...getInputProps()} disabled={!isEditing} />{' '}
      {/* Disable input if not editing */}
      {formik.values.photo ? (
        <img
          src={formik.values.photo}
          alt='Profile'
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            borderRadius: 12,
            border: 2,
          }}
        />
      ) : isEditing ? (
        <img
          src={`${process.env.PUBLIC_URL}/profileIcons/uploadImg.svg`}
          alt='Upload Icon'
        />
      ) : (
        <img
          src={process.env.PUBLIC_URL + '/logo-gray.png'}
          alt='Upload Icon'
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            borderRadius: 12,
            border: 2,
          }}
        />
      )}
    </DropzoneContainer>
  );
};

export default DropzoneImage;
