import React, { useEffect } from "react";
import { Card, CardContent, Typography, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import InputAdornment from "@mui/material/InputAdornment";
import { ReactComponent as ContactIcon } from "../Footer/footerIcons/contact.svg";
import StyledRating from "../Rating/StyledRating.jsx";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import { DEAL_FORWARD_WHATSAPP_MESSAGE } from "../../data/constants";

const useStyles = makeStyles(() => ({
    card: {
        position: "relative",
        display: "flex",
        flexDirection: "column",
        width: "100%",
        borderRadius: "12px !important",
        boxShadow: "0px 2px 35px 0px #00000015 !important",
        overflow: "hidden", // Ensures border-radius applies properly
    },
    petName: {
        display: 'flex',
        alignItems: 'center',
        "&.MuiTypography-root": {
            marginBottom: '8px',
            fontSize: '16px',
            fontWeight: 600,
            lineHeight: '24.8px',
            color: "#1F1F2A"
        },
    },
    content: {
        display: "flex",
        flexGrow: 1,
        flexDirection: "column",
        alignItems: "start",
        padding: "16px",
    },
    innerDetails: {
        display: 'flex',
        alignItems: 'center',
        "&.MuiTypography-root": {
            marginBottom: '8px',
            fontSize: '14px',
            fontWeight: 500,
            lineHeight: '21.7px',
            color: "#1F1F2A",
        },
    },
    icon: {
        width: 17,
        height: 17,
        fill: "#fff",
        // margin: "4px",
    },
    buttonGroup: {
        // make the button group sticky at the bottom
        display: 'flex',
        height: 48,
        // maxWidth: '600px', // Make it phone size only at 600px
        width: '100%',
        // padding: "16px 20px",
        gap: '8px',
        "& > button": {
            fontSize: "14px",
            fontWeight: 600,
            lineHeight: "21.7px",
            textTransform: "none",
        },
    },
    mediaContainer: {
        position: "relative", // Needed for absolute positioning of pagination
        width: "100%",
        height: "335px", // Fixed height for proper spacing
        borderTopLeftRadius: "12px",
        borderTopRightRadius: "12px",
        overflow: "hidden",
    },
    mediaImage: {
        width: "100%",
        height: "100%",
        objectFit: "cover", // Ensures image fills space
    },
    swiperContainer: {
        width: "100%",
        height: "100%", // Ensure swiper takes full height
        "& .swiper-pagination-bullet": {
            width: "6px",
            height: "6px",
            backgroundColor: "white !important",
            opacity: 0.5,
            transition: "opacity 0.3s ease-in-out",
        },
        "& .swiper-pagination-bullet-active": {
            opacity: 1,
            backgroundColor: "white !important",
            height: "8px",
            width: "8px",
        },
    },
    swiperPagination: {
        position: "absolute",
        bottom: "10px", // Keep dots inside image area
        left: "50%",
        transform: "translateX(-50%)",
        zIndex: 10, // Ensure it's above images
    },
    discountTag: {
        position: "absolute",
        top: "8px",
        right: "8px",
        backgroundColor: "#03A1AB",
        color: "#fff",
        padding: "5px 10px",
        borderRadius: "8px",
        zIndex: 10,
        height: 32,
        "& .MuiTypography-root": {
            fontSize: "14px",
            fontWeight: 600,
            lineHeight: "21.7px",
            textAlign: "left",
            color: "#FFFFFF",
        },
    },
}));

const DealCard = ({ deal }) => {
    const classes = useStyles();
    
    // Extract images from deal object
    const images = deal?.images?.length > 0 ? deal.images : [
        `${process.env.PUBLIC_URL}/vet-clinic-placeholder.png`,
    ];
    
    const [whatsappUrl, setWhatsappUrl] = React.useState("#");
    useEffect(() => {
        const getWhatsappUrl = async () => {
            const message = DEAL_FORWARD_WHATSAPP_MESSAGE;
            const encodedMessage = encodeURIComponent(message);
            const whatsappLink = `https://wa.me/${deal.whatsapp}?text=${encodedMessage}`;
            setWhatsappUrl(whatsappLink);
        };
        if (deal.whatsapp) getWhatsappUrl();
    }, [deal.whatsapp]);

    return (
        <Card className={classes.card} id={`deal-card-${deal.id}`}>
            {/* Image Carousel */}
            {images.length > 0 && (
                <div className={classes.mediaContainer}>
                    {deal?.max_deal && (
                        <div className={classes.discountTag}>
                            <Typography>{deal?.max_deal}</Typography>
                        </div>
                    )}

                    <Swiper
                        pagination={{ clickable: true }}
                        modules={[Pagination]}
                        loop
                        className={classes.swiperContainer}
                    >
                        {images.map((src, index) => (
                            <SwiperSlide key={index}>
                                <img src={src} alt={`slide-${index}`} className={classes.mediaImage} loading="lazy" />
                            </SwiperSlide>
                        ))}
                    </Swiper>

                </div>
            )}
            {/* Card Content */}
            <CardContent className={classes.content}>
                <Typography className={classes.petName}>{deal?.name || "Loading..."}</Typography>
                <Typography className={classes.innerDetails}>
                    {deal?.address || "Loading..."}
                </Typography>
                <div style={{ display: "flex", alignItems: "center", marginBottom: "16px" }}>
                    <StyledRating rating={deal?.google_reviews?.rating || ""} totalRatings={deal?.google_reviews?.number_of_reviews || "Not Available"} />
                </div>
                {/* Buttons */}
                <div className={classes.buttonGroup}>
                    <Button
                        variant="contained"
                        color="primary"
                        sx={{ width: "50%", padding: "0px 16px", borderRadius: "8px" }}
                        onClick={
                            // Handle call button click
                            () => {
                                window.location.href = `tel:${deal.phone}`;
                            }
                        }
                    >
                        <InputAdornment position="start">
                            <ContactIcon className={classes.icon} />
                        </InputAdornment>
                        Call
                    </Button>

                    <Button
                        variant="outlined"
                        sx={{
                            width: "50%",
                            padding: "0px 13px",
                            borderRadius: "8px",
                            color: "#000",
                            backgroundColor: "#fff",
                            boxShadow: "0px 2px 8px 0px #1E07511F",
                            border: "0px",
                        }}
                        onClick={
                            // Handle whatsapp button click (open whatsapp with message in new tab)
                            () => {
                                window.open(whatsappUrl, "_blank");
                            }
                        }
                    >
                        <InputAdornment position="start">
                            <img
                                style={{ width: "24px", height: "24px" }}
                                src={`${process.env.PUBLIC_URL}/socialIcons/whatsapp.svg`}
                                alt="whatsapp"
                            />
                        </InputAdornment>
                        Whatsapp
                    </Button>
                </div>
            </CardContent>
        </Card>
    );
};

export default DealCard;
