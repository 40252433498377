import { useEffect, useState, useCallback } from 'react';
import { toast } from 'react-toastify';
import ApiClient from '../utils/client';

const api = new ApiClient();

const useVetStorage = () => {

    const getVetsDeals = useCallback(async () => {
        try {
            const response = await api.get(`vet/deals/list`);
            if (response.error) {
                console.error("Error fetching vet deals from database:", response.error);
                toast.error("Error fetching vet deals from database");
                return [];
            }
            return response;
        } catch (error) {
            console.error("Error fetching vet deals:", error);
            return [];
        }
    }, []);

    const getVetDealsByClinic = useCallback(async (clinicId) => {
        try {
            const response = await api.get(`vet/deals/list-deals-by-clinic?clinic_id=${clinicId}`);
            if (response.error) {
                console.error("Error fetching vet deals from database:", response.error);
                toast.error("Error fetching vet deals from database");
                return [];
            }
            return response;
        } catch (error) {
            console.error("Error fetching vet deals:", error);
            return [];
        }
    }, []);


    return {
        getVetsDeals,
        getVetDealsByClinic,
    };
    
};

export default useVetStorage;
