import React, { useMemo } from 'react';
import { makeStyles } from "@mui/styles";
import ReactCountryFlag from "react-country-flag";
import myCountryCodesObject from "../../utils/countryCodeGenerator";
import { Select, MenuItem } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import PublicIcon from '@mui/icons-material/Public';

const useStyles = makeStyles((theme) => ({
  select: {
    minWidth: '84.6px',
    height: '56px',
    '& .MuiSelect-select': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '16.5px 16px',
      background: '#FFFFFF',
      boxShadow: '0px 2px 8px 0px #1E07511F',
      borderRadius: '8px',
      border: 'none',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
  },
  menuItem: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
  },
  flag: {
    width: '28px !important',
    height: '20px !important',
    // borderRadius: '2px',
    objectFit: 'cover',
  },
  dropdownIcon: {
    width: '8.6px',
    height: '4.7px',
    color: '#7D7D97',
  },
}));

const CountryDropdown = ({ countryCode, setCountryCode }) => {
  const classes = useStyles();
  const [countryCodes, setCountryCodes] = React.useState([]);

  useMemo(() => {
    setCountryCodes(myCountryCodesObject());
  }, []);

  const handleChange = (event) => {
    const selectedDialCode = event.target.value;
    const selectedCountry = countryCodes.find((c) => c.dial_code === selectedDialCode);

    if (selectedCountry) {
      setCountryCode('country_code', selectedCountry.dial_code);
    }
  };


  return (
    <Select
      className={classes.select}
      value={countryCode}
      onChange={handleChange}
      renderValue={(selected) => {
        const selectedCountry = countryCodes.find((c) => c.dial_code === selected); // TODO change to country iso code for duplicate dial code countries
        return (
          <div className={classes.menuItem}>
            {/* if the dial_code is +1, display a globe instead */}
          {selectedCountry?.dial_code === '+1' ? (
            <PublicIcon className={classes.flag} />  // Display globe for +1
          ) : (
            <ReactCountryFlag
              countryCode={selectedCountry?.code}
              svg
              className={classes.flag}
            />
          )}
          </div>
        );
      }}
      IconComponent={(props) => <KeyboardArrowDownIcon className={classes.dropdownIcon} {...props} />}
    >
      {countryCodes.map(({ code, dial_code, name }) => (
        <MenuItem key={code} value={dial_code} className={classes.menuItem}>
          <ReactCountryFlag
            countryCode={code}
            svg
            className={classes.flag}
          />
          <span>{name} ({dial_code})</span>
        </MenuItem>
      ))}
    </Select>
  );
};

export default CountryDropdown;
