import { customList } from 'country-codes-list';

// Use customList to generate the object
const myCountryCodesObject = () => {
  const countryCodes = customList(
    'countryCode', 
    '[{countryCode}] {countryNameEn}: +{countryCallingCode}'
  );

  // Transform the object into the desired array format
  return Object.entries(countryCodes).map(([code, value]) => {
    const match = value.match(/\[(.*?)\] (.*?): \+(\d*)/);
    if (match) {
      const [, countryCode, name, dial_code] = match;
      return {
        name,
        dial_code: `+${dial_code}`,
        code: countryCode,
      };
    }
    return null; // Skip if the match fails (e.g., Antarctica missing dial code)
  }).filter(Boolean); // Filter out null entries
};

export default myCountryCodesObject;
