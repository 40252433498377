import React from 'react';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

export default function ResponsiveDatePickers({ name, value, onChange, error, helperText, maxDate = {}, disable = false, disableFuture = false, disablePast = false, className = '' }) {
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
                value={value ? dayjs(value) : null} // Set the date value
                onChange={(newValue) => onChange(name, newValue ? newValue.toISOString() : '')} // Update Formik's value
                disabled={disable}
                disableFuture={disableFuture}
                maxDate={maxDate ? dayjs(maxDate) : null} // Set the maximum date
                minDate={disablePast ? dayjs() : null} // Disable past dates
                className={className}
                slotProps={{
                    textField: {
                        fullWidth: true,
                        error: Boolean(error),
                        helperText: helperText,
                        sx: {
                            width: '100%',
                            '& .MuiInputBase-input': {
                                fontSize: '16px',
                                fontWeight: 500,
                                lineHeight: '24.8px',
                                textAlign: 'left',
                                color: '#1F1F2A',
                            }
                        }
                    }
                }}
                sx={{
                    width: '100%',
                    background: 'var(--Light-Grey, #F6F7FB)', // Apply background
                    '& .MuiOutlinedInput-root': {
                        borderRadius: '8px', // Rounded corners
                        '& fieldset': {
                            border: '1px solid #E3E8FF', // Fieldset border
                        },
                        '&:hover fieldset': {
                            borderColor: '#E3E8FF', // Border color on hover
                        },
                        '&.Mui-focused fieldset': {
                            borderColor: '#7019FF', // Border color on focus
                        },
                    },

                }}
            />

        </LocalizationProvider>
    );
}
