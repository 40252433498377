import React, { useEffect, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import DealCard from './DealCard.jsx';
import useVetStorage from '../../managers/vetManager';
import { useNavigate } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import  { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    noDealsContainer: {
        // absolute center
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: 'fit-content',
        width: 'fit-content',
        color: '#7D7D97',
    },
}));

const DealList = ({ searchTerm }) => {
    const {
        getVetsDeals
    } = useVetStorage();
    
    const [deals, setDeals] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const classes = useStyles();

    useEffect(() => {
        const fetchDeals = async () => {
            setLoading(true);
            const deals = await getVetsDeals();
            setLoading(false);
            setDeals(deals);
        };

        fetchDeals();
    }, []);

    useEffect(() => {
        const filterDeals = async () => {
            if (loading) return;

            if (searchTerm === '') {
                setLoading(true);
                const deals = await getVetsDeals();
                setLoading(false);
                setDeals(deals);
                return;
            }

            const filteredDeals = deals.filter((deal) => {
                return (
                    deal.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    deal.address.toLowerCase().includes(searchTerm.toLowerCase())
                );
            });

            setDeals(filteredDeals);
        };

        filterDeals();
    }, [searchTerm]);

    // Testing event listener delegation
    useEffect(() => {
        const handleDealClick = (event) => {
            // Check if the clicked element is a button
            if (event.target.tagName.toLowerCase() === 'button') {
                return;
            }

            const card = event.target.closest('.MuiCard-root');
            if (card) {
                const dealId = card.id.replace('deal-card-', '');
                navigate(`/pets/clinic/${dealId}`);
            }
        };

        const gridContainer = document.querySelector('.deal-list-container');
        gridContainer.addEventListener('click', handleDealClick);

        return () => {
            gridContainer.removeEventListener('click', handleDealClick);
        };
    }, [navigate]);

    return (
        <Grid container spacing={2} mb={"65px"} className={`deal-list-container`}>

            {loading ? (
                <Grid item xs={12} className={classes.noDealsContainer}>
                    <CircularProgress />
                </Grid>
            ) : (deals && deals.length === 0) ? (
                <>
                    <Grid item xs={12} className={classes.noDealsContainer}>
                        <Typography variant="h6" align="center">
                            No deals found
                        </Typography>
                    </Grid>
                </>
            ) : (
                deals.map((deal) => (
                    <Grid item xs={12} sm={12} md={4} key={deal.id} mt={"20px"}>
                        <DealCard deal={deal} />
                    </Grid>
                ))
            )}
        </Grid>
    );
};

export default DealList;