import React, { useState } from 'react';
import {
    IconButton, Drawer, Grid, Typography, TextField, CircularProgress, List, ListItem, ListItemText
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import { makeStyles } from '@mui/styles';
import debounce from 'lodash.debounce';

const useStyles = makeStyles({
    title: {
        '&.MuiTypography-root': {
            fontSize: '18px',
            fontWeight: 600,
            color: 'rgba(31, 31, 42, 1)',
            marginBottom: '8px',
        }
    },
    drawerHeader: {
        padding: '16px',
    },
    searchField: {
        '& .MuiInputBase-root': {
            margin: '0px 20px',
            justifyContent: 'center',
            padding: "0px 20px",
            borderRadius: '8px',
        }
    },
    locationIcon: {
        color: '#7D7D97',
        marginRight: '8px',
    },
    listItem: {
        '&.MuiListItem-root': {
            padding: '14px 16px',
        }
    },
    drawerPaper: {
        borderRadius: '8px 8px 0 0',
        minHeight: '50vh',
    },
});

const LocationDrawer = ({ title, openDrawer, setOpenDrawer, setLocationField }) => {
    const classes = useStyles();
    const [searchTerm, setSearchTerm] = useState('');
    const [locations, setLocations] = useState([]);
    const [loading, setLoading] = useState(false);

    const handleCloseDrawer = () => {
        setOpenDrawer(false);
        setSearchTerm('');
        setLocations([]);
    };

    const fetchLocations = async (query) => {
        setLoading(true);
        try {
            const response = await fetch(`https://nominatim.openstreetmap.org/search?q=${query}&format=json&limit=3`);
            const data = await response.json();
            setLocations(data);
        } catch (error) {
            console.error('Error fetching locations:', error);
        } finally {
            setLoading(false);
        }
    };

    const debouncedFetchLocations = React.useRef(
        debounce(async (finalQuery) => {
            if (finalQuery.length >= 3) { // Only search for queries with length >= 3
                await fetchLocations(finalQuery); // Fetch data with the last query
            } else {
                setLocations([]); // Clear locations if query is too short
            }
        }, 500)
    ).current;


    // Handle input change and trigger debounced search
    const handleSearch = (e) => {
        const query = e.target.value;
        setSearchTerm(query); // Update input value in state
        debouncedFetchLocations(query); // Call debounced function with the latest query
    };

    return (
        <Drawer
            classes={{ paper: classes.drawerPaper }}
            anchor="bottom"
            open={openDrawer}
            onClose={handleCloseDrawer}
        >
            <Grid className={classes.drawerHeader} container justifyContent="space-between" alignItems="center">
                <Grid item>
                    <Typography className={classes.title}>{title}</Typography>
                </Grid>
                <Grid item>
                    <IconButton onClick={handleCloseDrawer}>
                        <CloseIcon />
                    </IconButton>
                </Grid>
            </Grid>

            <TextField
                className={classes.searchField}
                placeholder="Search location"
                variant="outlined"
                InputProps={{
                    startAdornment: <LocationOnOutlinedIcon className={classes.locationIcon} />,
                }}
                value={searchTerm}
                onChange={handleSearch}
            />

            {loading ? (
                <Grid container justifyContent="center" style={{ padding: '16px' }}>
                    <CircularProgress />
                </Grid>
            ) : (
                <List>
                    <ListItem className={classes.listItem} button>
                        <ListItemText
                            primary={`"${searchTerm}"`}
                            onClick={() => {
                                setLocationField(searchTerm);
                                handleCloseDrawer();
                            }}
                        />
                    </ListItem>
                    {locations.map((location, index) => (
                        <ListItem key={index} className={classes.listItem} button>
                            <ListItemText
                                primary={location.display_name}
                                onClick={() => {
                                    setLocationField(location.display_name);
                                    handleCloseDrawer();
                                }}
                            />
                        </ListItem>
                    ))}
                </List>
            )}
        </Drawer>
    );
};

export default LocationDrawer;
