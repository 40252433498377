import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import CustomTreetButtonHollow from '../Buttons/CustomTreetButtonHollow.jsx'; // Your custom button component
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import { paths } from '../../data/constants.js';
import useReminderStorage from '../../managers/reminderManager.jsx';
import Checkbox from '@mui/material/Checkbox';
import UpcomingCard from './UpcomingCard.jsx';
const useStyles = makeStyles((theme) => ({
    contentWrapper: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        paddingBottom: '70px',
    },
    contentContainer: {
        display: 'flex',
        width: '100%',
        height: '100%',
        "& .MuiGrid-root": {
            paddingTop: '16px',
            marginBottom: 'auto',
        },
    },
    petsListContainer: {
        "&.MuiGrid-root": {
            display: 'flex',
            // flexDirection: 'column',
            alignItems: 'center',
            alignContent: 'flex-start',

        },
        paddingBottom: '70px',
    },
    petsValue: {
        color: 'var(--Bright-Purple, #7019FF)',
        paddingLeft: '12px',
    },

    newPetButtonWrapper: {
        // center the button at the bottom of the screen
        display: 'flex',
        position: 'fixed',
        bottom: '0dvh',
        left: '50%',
        transform: 'translateX(-50%)',
        zIndex: 1000,
        margin: '16px 0px 66px 0px',
        width: "fit-content",
        justifyContent: "center",
        alignItems: 'center',

    },
    noPetsContainer: {
        position: 'absolute',  // Added to center the message like in PetList
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: 'fit-content',
        width: 'fit-content',
        color: '#7D7D97',
    },
    showPastRemindersContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '8px',
        width: '100%',
        padding: "20px",
        "& .MuiTypography-root": {
            fontSize: '16px',
            fontWeight: 500,
            lineHeight: '24.8px',
            textAlign: 'left',
            color: '#1F1F2A',
        }
    },
}));

const ShowPastRemindersCheckbox = ({ showPastReminders, setShowPastReminders }) => {
    const classes = useStyles();
    const handleChange = (event) => {
        setShowPastReminders(event.target.checked);
    };
    return (
        <div className={classes.showPastRemindersContainer}>
            <Typography
                style={{
                    fontSize: '14px',
                    //semibold
                    fontWeight: 600,
                    lineHeight: '21.7px',
                }}
            >Show Past Reminders</Typography>
            <Checkbox
                checked={showPastReminders}
                onClick={handleChange}
                inputProps={{ 'aria-label': 'controlled' }}

            />
        </div>
    );
};


const UpcomingList = ({ pets, setReminderCount }) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const [showPastReminders, setShowPastReminders] = useState(true);
    const [displayedItems, setDisplayedItems] = useState([]);
    const {
        data: items,
        updateItem: updateEntry,
        deleteItem: deleteEntry,
    } = useReminderStorage('reminder');

    useEffect(() => {
        setDisplayedItems(Object.values(items).sort((a, b) => {
            return new Date(b.date) - new Date(a.date);
        }));
    }, [items]);


    useEffect(() => {
        // Update list based on showPastReminders
        if (showPastReminders) {
            setDisplayedItems(Object.values(items).sort((a, b) => {
                return new Date(b.date) - new Date(a.date);
            }));
        } else {
            setDisplayedItems(Object.values(items).filter((item) => {
                return new Date(item.date) >= new Date();
            }).sort((a, b) => {
                return new Date(b.date) - new Date(a.date);
            }));
        };

    }, [showPastReminders]);

    // useEffect(() => {
    //     setReminderCount(displayedItems.length);
    // }, [displayedItems]);

    const handleNewReminder = () => {
        navigate(paths.petReminderCreate);
    }
    return (
        <div className={classes.contentWrapper}>
            <div className={classes.contentContainer}>
                <ShowPastRemindersCheckbox showPastReminders={showPastReminders} setShowPastReminders={setShowPastReminders} />
            </div>

            {Object.keys(displayedItems).length > 0 ? (
                    Object.values(displayedItems).map((item) => (
                        <UpcomingCard
                            key={item.id}
                            pet={pets[item.pet]}
                            reminder={item}
                            handleDeleteEntry={deleteEntry}
                            handleUpdateEntry={updateEntry}
                        />
                    ))
                )
                    : (
                        <div className={classes.noPetsContainer}>
                            <img src={`${process.env.PUBLIC_URL}/logo-gray.svg`} alt='logo' style={{
                                marginBottom: '20px',
                            }}/>
                            <Typography>You’re all caught up</Typography>
                        </div>
                    )}
            <div className={classes.newPetButtonWrapper}>
                <CustomTreetButtonHollow
                    icon="Add"
                    onClick={handleNewReminder}
                    variant="filled"
                    type="pet"
                    style={{
                        backgroundColor: 'var(--White, #FFFFFF)',
                        boxShadow: '0px 2px 35px 0px #00000026',
                        // maxWidth: '132px',
                        maxHeight: '40px',
                        padding: '0px 16px 0px 16px',
                        // gap: '6px',
                        borderRadius: '55px',
                        textTransform: 'none',
                    }}
                >
                    New reminder
                </CustomTreetButtonHollow>
            </div>
        </div>
    );
};

export default UpcomingList;
