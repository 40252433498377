import React from 'react';
import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import EditableText from '../EditableText/EditableText.jsx';
import { useState } from 'react';
import ReusableMenu from '../Menus/ReusableThreeMenuSession.jsx';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import IosShareOutlinedIcon from '@mui/icons-material/IosShareOutlined';
import LiveTranscriptionV2 from '../Recorder/recorderV2.jsx';
import { LiveAudioVisualizer } from 'react-audio-visualize';
import AwesomeLoader from '../Loaders/AwesomeLoader.jsx';

const useStyles = makeStyles((theme) => ({
    transcriptionContainer: {
        width: '100%',
        height: '100%',
        overflowY: 'auto',
    },
    transcriptionBody: {
        padding: '16px',
        height: 'calc(100dvh - 310px)',
        [theme.breakpoints.up('sm')]: {
            height: 'calc(100dvh - 200px)',
        },
        overflowY: 'auto',
        // width: '100%',
        backgroundColor: 'var(--White, #FFFFFF)',
        border: '1px solid var(--Light-Purple, #E3E8FF)',
        // all children with tag textarea
        '& textarea': {
            height: '100%',
            fontSize: '16px',
            fontWeight: 500,
            lineHeight: '30px',
        },
    },
    transcription: {
        '&.MuiTypography-root': {
            fontSize: '16px',
            fontWeight: 600,
            lineHeight: '19.5px',
        },
    },
    recorderItem: {
        width: '100%',
        height: 'calc(100dvh - 250px)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',

    },
    loaderContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 'calc(100dvh - 250px)',
    },
}));

const Transcription = ({ session, width, isSmallScreen, handleSessionUpdate, readOnly }) => {
    const classes = useStyles();
    const [transcription, setTranscription] = useState(session?.transcription || '');
    const [isEditing, setIsEditing] = useState(false);
    const [isRecording, setIsRecording] = useState(false);
    const [mediaRecorder, setMediaRecorder] = useState(null);
    const [isProcessing, setIsProcessing] = useState(false); // Loader state
    const [isTranscriptionReady, setIsTranscriptionReady] = useState(false); // To toggle edit stage

    const handleSave = (newText) => {
        setTranscription(newText);
        handleSessionUpdate(session.id, { transcription: newText });
    }

    const handleCopy = () => {
        navigator.clipboard.writeText(transcription);
    }


    const menuItems = [
        {
            label: 'Copy transcript',
            icon: <ContentCopyRoundedIcon />,
            action: () => handleCopy(),
            disable: !transcription,
        },
        {
            label: 'Edit transcript',
            icon: <BorderColorOutlinedIcon />,
            action: () => setIsEditing(true),
            disable: false,
        },
        {
            label: 'Share transcript',
            icon: <IosShareOutlinedIcon />,
            action: () => console.log('share action'),
            disable: !transcription,
        },
    ];

    return (
        <Grid container className={classes.transcriptionContainer} flexDirection='column' wrap='nowrap' width={width}>
            <Grid container minHeight={50} padding={"16px 0px"} flexDirection='row' wrap='wrap' alignItems='center' justifyContent={'space-between'}>
                <Typography className={classes.transcription}>
                    Transcription
                </Typography>
                {isSmallScreen && (
                    <ReusableMenu session={session} menuItems={menuItems} />
                )}

                <LiveTranscriptionV2 session={session} handleSessionUpdate={handleSessionUpdate} transcription={transcription}
                    setTranscription={setTranscription} saveTranscription={handleSave}
                    isRecording={isRecording} setIsRecording={setIsRecording}
                    mediaRecorder={mediaRecorder} setMediaRecorder={setMediaRecorder}
                    setIsProcessing={setIsProcessing} isProcessing={isProcessing}
                    isTranscriptionReady={isTranscriptionReady} setIsTranscriptionReady={setIsTranscriptionReady}
                    disable={readOnly}
                />

            </Grid>
            <Grid item>
                {(isRecording && mediaRecorder) ? (
                    <Grid item className={classes.recorderItem}>
                        <LiveAudioVisualizer
                            mediaRecorder={mediaRecorder}
                            width={isSmallScreen ? 250 : 500}
                            height={isSmallScreen ? 250 : 320}
                            barWidth={4}
                            gap={2.87}
                            barColor={'#7019FF'}
                            smoothingTimeConstant={0.8}
                            style={{
                                height: '100%',
                                width: '100%',
                            }}
                        />
                    </Grid>
                ) : isProcessing ? (
                    <div className={classes.loaderContainer}>
                        <AwesomeLoader />
                        <Typography ml={2}>Processing audio, please wait...</Typography>
                    </div>
                ) : (
                    <EditableText
                        text={transcription}
                        clickable={!isSmallScreen}
                        className={classes.transcriptionBody}
                        onSave={handleSave}
                        isEditing={isEditing}
                        multiline={true}
                        setIsEditing={setIsEditing}
                        width={'100%'}
                        placeholder='Start a recording and your transcript will appear here'
                        isTitle={false}
                        shouldValidate={false}
                    />)}

                <div className={classes.divideLine}></div>

            </Grid>
        </Grid>
    );
};

export default Transcription;