import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@mui/styles';
import CustomTreetButton from '../Buttons/CustomTreetButton.jsx';
import Grid from '@mui/material/Grid';
import StopCircleRoundedIcon from '@mui/icons-material/StopCircleRounded';
import { useStopwatch } from 'react-timer-hook';
import { Typography, useMediaQuery, useTheme } from '@mui/material';
import { transcribeAudio } from '../../utils/googleSpeechAPI';
import RecordRTC from 'recordrtc';

const useStyles = makeStyles(theme => ({
    recorderItem: {
        "&.MuiGrid-item": {
            marginRight: '12px',
            [theme.breakpoints.down('sm')]: {
                marginRight: '6px',
            },
        },

    },
    recorderContainer: {
        '&.MuiGrid-container': {
            width: 'fit-content',
            // [theme.breakpoints.down(850)]: {
            // center the button at the bottom of the screen
            [theme.breakpoints.down('sm')]: {
                position: 'fixed',
                bottom: '0dvh',
                left: '50%',
                transform: 'translateX(-50%)',
                zIndex: 1000,
                backgroundColor: 'var(--White, #FFFFFF)',
                padding: '16px 24px',
                width: "100%",
                justifyContent: 'space-between',
                borderTop: '1px solid var(--Mid-Blue, #E3E8FF)'
            },
        }

    },
    timer: {
        "&.MuiTypography-root": {
            fontSize: '24px',
            fontWeight: 600,
            lineHeight: '29.26px',
        },
    },
    recording: {
        color: '#1F1F2A', // Red color when recording
    },
    notRecording: {
        color: '#7D7D97', // Gray color when not recording
    },
    recorderControls: {
        "&.MuiGrid-item": {
            display: "flex",
            justifyContent: 'end',
            alignItems: 'center',
            flexWrap: 'nowrap',
        }
    }
}));

const RECORDING_STATE = {
    NOT_RECORDING: 0,
    RECORDING: 1,
    PAUSED: 2,
};

const LiveTranscriptionV2 = ({ session, handleSessionUpdate, transcription, setTranscription, saveTranscription, 
    isRecording, setIsRecording,
    mediaRecorder, setMediaRecorder,
    isProcessing, setIsProcessing,
    isTranscriptionReady, setIsTranscriptionReady,
    disable
}) => {

    const transcriptionTimeInS = session?.transcriptionTime ? session.transcriptionTime : 0;
    const stopwatchOffset = new Date(); 
    stopwatchOffset.setSeconds(stopwatchOffset.getSeconds() + transcriptionTimeInS);

    // Initialize useStopwatch with correct offset
    const { seconds, minutes, start, pause, reset } = useStopwatch({
        autoStart: false,
        offsetTimestamp: stopwatchOffset,
    });

    const classes = useStyles();
    const [recordingState, setRecordingState] = useState(RECORDING_STATE.NOT_RECORDING); // 0: not recording, 1: recording, 2: paused
    const [audioStream, setAudioStream] = useState(null);
    const [audioRecorder, setAudioRecorder] = useState(null);
    const [processing, setProcessing] = useState(false); // Processing state

    const chunkDuration = 10000; // Duration of each chunk in milliseconds (10 seconds)
    const chunkQueue = useRef([]); // Queue to store audio chunks
    const audioBlobRef = useRef(null); // Store audio blob after recording

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    // store the transcription time in the session
    useEffect(() => {
        handleSessionUpdate(session.id, { transcriptionTime: minutes * 60 + seconds });
    }, [minutes, seconds]);
    
    useEffect(() => {
        saveTranscription(transcription)
        if (transcription?.length === 0) {
            reset(0, false);
        }
    }, [transcription]);

    useEffect(() => {
        if (isRecording) {
            navigator.mediaDevices.getUserMedia({ audio: true })
                .then(stream => {
                    setAudioStream(stream);
                    const recorder = new RecordRTC(stream, {
                        type: 'audio',
                        mimeType: 'audio/webm',
                        sampleRate: 44100,
                        bitsPerSecond: 128000,
                    });

                    recorder.startRecording();
                    setAudioRecorder(recorder);

                    const mediaRecorder = new MediaRecorder(stream);
                    mediaRecorder.start();
                    setMediaRecorder(mediaRecorder);

                    // Automatically stop recording and send the chunk after `chunkDuration`
                    const intervalId = setInterval(() => {
                        recorder.stopRecording(() => {
                            const audioBlob = recorder.getBlob();
                            audioBlobRef.current = audioBlob; // Store audioBlob for processing
                            // Enqueue the audio blob for processing
                            chunkQueue.current.push(audioBlob);
                            processNextChunk(); // Start processing the next chunk
                            // Start recording again for the next chunk
                            recorder.reset();
                            recorder.startRecording();
                        });
                    }, chunkDuration);

                    // Clear the interval when recording stops
                    return () => clearInterval(intervalId);
                })
                .catch(err => {
                    console.error("Error accessing microphone: ", err);
                });
        } else {
            if (audioRecorder) {
                audioRecorder.stopRecording(async () => {
                    const audioBlob = audioRecorder.getBlob();
                    audioBlobRef.current = audioBlob; // Store audioBlob for processing
                    // Check if the queue has the current chunk
                    if (chunkQueue.current.length === 0 || chunkQueue.current[chunkQueue.current.length - 1] !== audioBlob) {
                        // Enqueue the audio blob for processing
                        chunkQueue.current.push(audioBlob);
                    }
                    if (audioStream) {
                        audioStream.getTracks().forEach(track => track.stop());
                    }
                    if(mediaRecorder) {
                        mediaRecorder.stop();
                    }
                    setAudioStream(null);
                    setAudioRecorder(null);
                    setMediaRecorder(null); // Clear mediaRecorder state
                    await finalizeTranscription(); // Process the last chunk
                });
            }
        }

        return () => {
            if (audioRecorder) {
                audioRecorder.stopRecording(() => {
                    if (audioStream) {
                        audioStream.getTracks().forEach(track => track.stop());
                    }
                    setAudioStream(null);
                    setAudioRecorder(null);
                });
            }
        };
    }, [isRecording]);

    const processNextChunk = async () => {
        if (processing || chunkQueue.current.length === 0) return; // Exit if already processing or no chunks in queue

        setProcessing(true);
        const audioBlob = chunkQueue.current.shift(); // Get the next audio blob from the queue
        try {
            const newTranscription = await transcribeAudio(audioBlob); // Transcribe audio
            setTranscription(prev => prev ? prev + " " + newTranscription : newTranscription); // Append to the final transcription
        } catch (error) {
            console.error('Transcription error:', error);
        } finally {
            setProcessing(false);
            processNextChunk(); // Process the next chunk
        }
    };

    const finalizeTranscription = async () => {
        // This function can finalize the transcription if needed
        try {
            setIsProcessing(true); // Show loader
            if (audioBlobRef.current) {
                await processNextChunk(audioBlobRef.current); // Process the last chunk
            }
            setTranscription(prev => prev); // Ensure final transcription is set
            setIsTranscriptionReady(true); // Show text edit stage
        } catch (error) {
            console.error('Final transcription error:', error);
        } finally {
            setIsProcessing(false); // Hide loader
        }
    };

    const startRecording = () => {
        setRecordingState(RECORDING_STATE.RECORDING);
        setIsRecording(true);
        start(); // start the timer
        setIsTranscriptionReady(false); // Hide edit stage during new recording
    };

    const stopRecording = () => {
        setRecordingState(RECORDING_STATE.NOT_RECORDING);
        setIsRecording(false);
        pause(); // pause the timer
    };
    const pauseRecording = () => {
        setRecordingState(RECORDING_STATE.PAUSED);
        setIsRecording(false);
        pause(); // pause the timer
    }

    const resumeRecording = () => {
        setRecordingState(RECORDING_STATE.RECORDING);
        setIsRecording(true);
        start(); // resume the timer
    }

    // Helper function to format the time
    const formatTime = (minutes, seconds) => {
        const paddedSeconds = seconds < 10 ? `0${seconds}` : seconds;
        return `${minutes}:${paddedSeconds}`;
    };

    return (
        <Grid container justifyContent={'end'} alignItems={'center'} wrap='nowrap' className={classes.recorderContainer} >
            <Grid item className={`${classes.recorderItem} ${classes.recorderTimer}`}>
                <Typography className={`${classes.timer} ${recordingState === RECORDING_STATE.NOT_RECORDING ? classes.notRecording : classes.recording}`}>
                    {formatTime(minutes, seconds)}
                </Typography>
            </Grid>
            <Grid container justifyContent={'end'} alignItems={'center'} wrap='nowrap' className={classes.recorderControls}>
                {recordingState !== RECORDING_STATE.NOT_RECORDING && (
                    <Grid item style={{ height: 40, width: 40, minWidth: 40 }} className={classes.recorderItem}>
                        <StopCircleRoundedIcon
                            style={{ height: '100%', width: '100%' }}
                            sx={{
                                color: "#FF6058",
                            }}
                            onClick={stopRecording}
                        />
                    </Grid>
                )}
                <Grid item>
                    {recordingState === RECORDING_STATE.NOT_RECORDING ? (
                        <CustomTreetButton disabled={disable} icon="MicNone" onClick={startRecording}>
                            {isMobile ? "Start" : "Start Dictation"}
                        </CustomTreetButton>
                    ) : recordingState === RECORDING_STATE.RECORDING ? (
                        <CustomTreetButton icon="Pause" onClick={pauseRecording}>
                            Pause
                        </CustomTreetButton>
                    ) : (
                        <CustomTreetButton icon="MicNone" onClick={resumeRecording}>
                            {isMobile ? "Resume" : "Resume Dictation"}
                        </CustomTreetButton>
                    )}
                </Grid>
            </Grid>
        </Grid>
    );
};

export default LiveTranscriptionV2;
