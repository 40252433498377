import React from 'react';
import {
    IconButton, Drawer, Grid, Typography, Button
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from '@mui/styles';
import MainReminderDetailsFormDrawer from '../Forms/ReminderFormSections/MainReminderDetailsFormDrawer';

const useStyles = makeStyles({
    title: {
        '&.MuiTypography-root': {
            fontSize: '18px',
            fontWeight: 600,
            color: 'rgba(31, 31, 42, 1)',
            marginBottom: '8px',
        }
    },
    drawerHeader: {
        padding: '16px',
    },
    searchField: {
        '& .MuiInputBase-root': {
            margin: '0px 20px',
            justifyContent: 'center',
            padding: "0px 20px",
            borderRadius: '8px',
        }
    },
    locationIcon: {
        color: '#7D7D97',
        marginRight: '8px',
    },
    listItem: {
        '&.MuiListItem-root': {
            padding: '14px 16px',
        }
    },
    drawerPaper: {
        borderRadius: '8px 8px 0 0',
        minHeight: '50vh',
    },
    buttonGroup: {
        display: 'flex',
        position: 'relative',
        bottom: '0',
        height: 88,
        left: '50%',
        transform: 'translateX(-50%)',
        zIndex: 1000,
        maxWidth: '600px',
        width: '100%',
        padding: "20px",
        background: "#fff",
        "& > button": {
            fontSize: "16px",
            fontWeight: 600,
            lineHeight: "19.5px",
            textTransform: "none",
        },
    },
});

const ReminderDrawer = ({ title, openDrawer, setOpenDrawer, formik }) => {
    const classes = useStyles();

    const handleCloseDrawer = () => {
        setOpenDrawer(false);
    };

    const handleDiscard = () => {
        formik.setFieldValue('diary_items', {
            title: '',
            date: '',
            time: '',
            location: '',
            type: 'reminder',
        });
        setOpenDrawer(false);
    }

    const handleSave = () => {
        setOpenDrawer(false);
    }

    const isValid = () => {
        const hasReminderFields = formik.values.diary_items.title &&
            formik.values.diary_items.date &&
            formik.values.diary_items.time;
        return hasReminderFields;
    }

    return (
        <Drawer
            classes={{ paper: classes.drawerPaper }}
            anchor="bottom"
            open={openDrawer}
            onClose={handleCloseDrawer}
        >
            <Grid className={classes.drawerHeader} container justifyContent="space-between" alignItems="center">
                <Grid item>
                    <Typography className={classes.title}>{title}</Typography>
                </Grid>
                <Grid item>
                    <IconButton onClick={handleCloseDrawer}>
                        <CloseIcon />
                    </IconButton>
                </Grid>
            </Grid>
            <MainReminderDetailsFormDrawer formik={formik} />
            <div className={classes.buttonGroup}>
                <Button
                    variant="outlined"
                    onClick={handleDiscard}
                    sx={{
                        marginRight: "16px",
                        width: "25%",
                        padding: "0px 16px",
                        gap: "8px",
                        borderRadius: "8px",
                        color: "#000",
                        backgroundColor: "#fff",
                        boxShadow: "0px 2px 8px 0px #1E07511F",
                        border: "0px",
                    }}
                >
                    {"Cancel"}
                </Button>

                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSave}
                    sx={{ width: "75%" }}
                    disabled={formik.isSubmitting || !isValid() || !formik.dirty}
                >
                    {"Save Entry"}
                </Button>
            </div>
        </Drawer>
    );
};

export default ReminderDrawer;
