import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import { TextField } from '@mui/material';
import ResponsiveDatePickers from '../../DatePicker/datePicker';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import LocationDrawer from '../../Menus/LocationDrawer';
import TimeInput from '../../Utils/timeInput';
// import GoogleMapsComponent from '../../Maps/GoogleMapsComponent';

const useStyles = makeStyles((theme) => ({
    formContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        padding: '16px',
    },
    formItem: {
        marginBottom: '16px',
    },
    iconContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        width: '100%',
        paddingBottom: '8px',
        gap: '8px',
    },
    iconText: {
        '&.MuiTypography-root': {
            fontSize: '14px',
            fontWeight: 600,
            color: "#1F1F2A",
            lineHeight: '21.7px',
        }
    },
    icon: {
        width: '24px',
        height: '24px',
        marginRight: '8px',
    },
    inputTextBox: {
        // padding: '16px',
        width: '100%',
        background: 'var(--Light-Grey, #F6F7FB)',
        '& .MuiOutlinedInput-root': {
            borderRadius: '8px',
            '& fieldset': {
                border: '1px solid #E3E8FF',
            },
        },
        '& .MuiInputBase-input': {
            fontSize: '16px',
            fontWeight: 500,
            lineHeight: '24.8px',
            textAlign: 'left',
            color: '#1F1F2A',
        },
    },
    textIdFields: {
        textAlign: 'left',
        color: '#7D7D97',
        fontSize: 12,
        lineHeight: '18.6px',
        fontWeight: 600,
        marginBottom: '8px',
    },
    chip: {
        height: '24px',
        borderRadius: '32px',
        fontSize: '12px',
        lineHeight: '18.6px',
        fontWeight: 600,
        background: 'var(--Light-Grey, #F6F7FB) !important',
        width: 'fit-content',
    },
    detailSection: {
        marginBottom: '16px',
    },
    detailSectionTitle: {
        marginBottom: '24px',
    },
    detailTitle: {
        "&.MuiTypography-root": {
            fontSize: '18px',
            fontWeight: 600,
            lineHeight: '21.94px',
            textAlign: 'left',
            color: '#1F1F2A',
        },
    },
    chipLabel: {
        "&.MuiChip-label": {
            padding: '8px',
        },
    },
    breedGroup: {
        display: 'flex',
        gap: '8px',
        width: '100%',
    },
}));


const MainReminderDetailsForm = ({ formik }) => {
    const classes = useStyles();
    const [locationDrawerOpen, setLocationDrawerOpen] = useState(false);

    const openLocationDrawer = () => {
        setLocationDrawerOpen(true);
    };

    return (
        <div style={{ width: "100%" }} >
            <div className={classes.detailSectionTitle}>
                <div className={classes.iconContainer}>
                    <Typography align="left" className={classes.iconText}>
                        Event Title
                    </Typography>
                </div>
                <TextField
                    name="title"
                    value={formik.values.title}
                    placeholder='Enter event title'
                    onChange={formik.handleChange}
                    className={classes.inputTextBox}
                    error={formik.touched.title && Boolean(formik.errors.title)}
                    helperText={formik.touched.title && formik.errors.title}
                />
            </div>
            <div className={classes.detailSection}>
                <div className={classes.iconContainer}
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        width: '100%'
                    }}>
                    <Typography align="left" className={classes.iconText}>
                        Location
                    </Typography>
                </div>
                <TextField
                    name="location"
                    variant="outlined"
                    value={formik.values.location}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className={classes.inputTextBox}
                    placeholder='Enter location'
                    helperText={formik.touched.location && formik.errors.location}
                    InputProps={{
                        startAdornment: <LocationOnOutlinedIcon
                            onClick={openLocationDrawer}
                            sx={{
                                color: '#7019FF',
                                fontSize: '20px',
                                marginLeft: '8px',
                                marginRight: '8px',
                            }}
                        />,
                    }}
                />
                <br></br>
                {/* <GoogleMapsComponent /> */}
                <LocationDrawer
                    title="Search Locations"
                    openDrawer={locationDrawerOpen}
                    setOpenDrawer={setLocationDrawerOpen}
                    setLocationField={(location) => { formik.setFieldValue('location', location) }}
                />
            </div>

            <div className={classes.detailSection}>
                <div className={classes.iconContainer}>
                    <Typography align="left" className={classes.iconText}>
                        Date
                    </Typography>
                </div>
                <ResponsiveDatePickers
                    name="date"
                    value={formik.values.date || null}
                    onChange={(key, value) => {
                        // Ensure the value is in the correct format, handling timezone
                        const formattedValue = value ? new Date(value).toLocaleDateString('en-CA') : null;
                        formik.setFieldValue(key, formattedValue);
                    }} error={formik.touched.date && Boolean(formik.errors.date)}
                    helperText={formik.touched.date && formik.errors.date}
                    ResponsiveDatePickers
                    disablePast
                    format="dd/MM/yyyy"
                    placeholder="Select date"
                />
                {formik.errors.date && (
                    <Typography color="error">{formik.errors.date}</Typography>
                )}
            </div>
            <div className={classes.detailSection}>
                <div className={classes.iconContainer}>
                    <Typography align="left" className={classes.iconText}>
                        Time
                    </Typography>
                </div>
                {/* Two inputs for hours and mins with dropdown for AM and PM */}
                <TimeInput
                    value={formik.values.time}
                    onChange={formik.handleChange}
                    error={formik.touched.time && Boolean(formik.errors.time)}
                    helperText={formik.touched.time && formik.errors.time}
                />
            </div>

        </div >
    );
};

export default MainReminderDetailsForm;
