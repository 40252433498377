import React, { useState, useEffect } from 'react';
import { Editor, EditorState, convertToRaw, convertFromRaw } from 'draft-js';
// import { stateToHTML } from 'draft-js-export-html';
import { stateFromHTML } from 'draft-js-import-html';
import 'draft-js/dist/Draft.css';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    container: {
        padding: '0px 16px',
        height: 'calc(100dvh - 380px)',
        [theme.breakpoints.up('sm')]: {
            height: 'calc(100dvh - 250px)',
        },
        overflowY: 'auto',
        // width: '100%',
        backgroundColor: 'var(--White, #FFFFFF)',
        border: 'none',
        borderRadius: '12px',
    }
}));

const SummaryTextFieldV2 = ({ initialJsonText, handleSave, contentSource, setContentSource, setIsEditing, isEditing, clickable }) => {
    const styles = useStyles();
    const [editorState, setEditorState] = useState(EditorState.createEmpty());

    useEffect(() => {
        if (initialJsonText) {
            if (contentSource === 'api') {
                const parsedHtml = parseJsonToHtml(initialJsonText);
                const contentState = stateFromHTML(parsedHtml);
                setEditorState(EditorState.createWithContent(contentState));
                // persist the new content to local storage
                const content = convertToRaw(contentState);
                const jsonString = JSON.stringify(content);
                handleSave(jsonString);
            } else if (contentSource === 'localStorage') {
                const parsedRaw = JSON.parse(initialJsonText);
                const content = convertFromRaw(parsedRaw);
                setEditorState(EditorState.createWithContent(content));
            }
        }
    }, [initialJsonText]);


    const parseJsonToHtml = (jsonString) => {
        try {
            const data = JSON.parse(jsonString);
            let html = '';


            data.sections.forEach(section => {
                html += `<h4 style="font-size: 14px; color:var(--Faux-Black, #1F1F2A); font-weight:700; line-height:30px; margin-bottom: 8px;">${section.title}</h4>`;
                html += '<ul style="list-style-type: none; padding-left: 0; font-weight: 500;">';
                section.items.forEach(item => {
                    let itemHtml = `<li>${item.text}`;
                    if (item.bold) itemHtml = `<strong>${itemHtml}</strong>`;
                    if (item.italic) itemHtml = `<em>${itemHtml}</em>`;
                    if (item.underline) itemHtml = `<u>${itemHtml}</u>`;
                    html += `${itemHtml}</li>`;
                });
                html += '</ul>';
            });

            return html;
        } catch (error) {
            console.error('Error parsing JSON:', error);
            return '';
        }
    };
    const handleEditorChange = (newEditorState) => {
        setEditorState(newEditorState);
    };

    const saveContent = () => {
        const content = convertToRaw(editorState.getCurrentContent());
        const jsonString = JSON.stringify(content);
        handleSave(jsonString);
        setContentSource('localStorage');  // Mark as saved to localStorage
    };

    const handleBlur = saveContent;

    useEffect(() => {
        setIsEditing(clickable);
    }, [clickable]);

    const handleMouseDown = (e) => {
        e.preventDefault();
    };
    return (
        <div className={styles.container}>
            <Editor
                editorState={editorState}
                onChange={handleEditorChange}
                onBlur={handleBlur}
                placeholder="Enter your summary here..."
                onMouseDown={(!clickable && !isEditing) ? handleMouseDown : undefined}
                readOnly={!isEditing}

            // onKeyDown= {handleKeyDown}

            />
            {/* <button onClick={saveContent} className={styles.saveButton}>
                <Save className={styles.saveIcon} size={16} />
                Save
            </button> */}
        </div>
    );
};

export default SummaryTextFieldV2;
