import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Typography, Button, Container, TextField, Card } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import { paths, diaryEntryTypes } from '../../data/constants.js';
import Header from '../Header/header';
import PetProfileFormHeader from '../Header/petProfileFormHeader';
import ResponsiveDatePickers from '../DatePicker/datePicker.jsx';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import ApiClient from '../../utils/client';
import LocationDrawer from '../Menus/LocationDrawer.jsx';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import ReminderDrawer from '../Menus/ReminderDrawer.jsx';
import NotificationsNoneRoundedIcon from '@mui/icons-material/NotificationsNoneRounded';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import { CircularProgress } from '@mui/material';
import { toast } from 'react-toastify';

const useStyles = makeStyles((theme) => ({
    formContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        padding: '24px 0 100px 0px',
    },
    formItem: {
        marginBottom: '16px',
    },
    buttonGroup: {
        display: 'flex',
        position: 'fixed',
        bottom: '0',
        height: 88,
        left: '50%',
        transform: 'translateX(-50%)',
        zIndex: 1000,
        maxWidth: '600px',
        width: '100%',
        padding: "20px",
        background: "#fff",
        "& > button": {
            fontSize: "16px",
            fontWeight: 600,
            lineHeight: "19.5px",
            textTransform: "none",
        },
    },
    chip: {
        height: '24px',
        borderRadius: '32px',
        fontSize: '12px',
        lineHeight: '18.6px',
        fontWeight: 600,
        background: 'var(--Light-Grey, #F6F7FB) !important',
        width: 'fit-content',
    },
    detailSection: {
        marginBottom: '16px',
    },
    detailSectionTitle: {
        marginBottom: '24px',
    },
    detailTitle: {
        "&.MuiTypography-root": {
            fontSize: '18px',
            fontWeight: 600,
            lineHeight: '21.94px',
            textAlign: 'left',
            color: '#1F1F2A',
        },
    },
    iconContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        width: '100%',
        paddingBottom: '8px',
        gap: '8px',
    },
    iconText: {
        '&.MuiTypography-root': {
            fontSize: '14px',
            fontWeight: 600,
            color: "#1F1F2A",
            lineHeight: '21.7px',
        }
    },
    icon: {
        width: '24px',
        height: '24px',
        marginRight: '8px',
    },
    inputTextBox: {
        // padding: '16px',
        width: '100%',
        background: 'var(--Light-Grey, #F6F7FB)',
        '& .MuiOutlinedInput-root': {
            borderRadius: '8px',
            '& fieldset': {
                border: '1px solid #E3E8FF',
            },
        },
        '& .MuiInputBase-input': {
            fontSize: '16px',
            fontWeight: 500,
            lineHeight: '24.8px',
            textAlign: 'left',
            color: '#1F1F2A',
        },
    },
    imageRow: {
        display: 'flex',
        gap: '10px',
        marginBottom: '10px',
    },
    imageContainer: {
        position: 'relative', // Enables absolute positioning of the "Remove Photo" icon
        width: '100px',
        height: '100px',
        borderRadius: '8px',
        border: '1px solid #E3E8FF',
    },
    imagePreview: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
    },
    removeIcon: {
        "&.MuiIconButton-root": {
            position: 'absolute',
            top: '-10px',
            right: '-10px',
            backgroundColor: 'rgba(0, 0, 0, 0.6)', // Semi-transparent background for better visibility
            color: 'white',
            borderRadius: '50%',
            padding: '2px',
            cursor: 'pointer',
            zIndex: 4, // Ensures the icon is above the image
            '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.8)',
            },
        },
    },

}));


const ExpandButton = ({ children, ...props }) => {
    return (
        <Button
            variant="outlined"
            color="secondary"
            {...props}
            startIcon={<AddIcon />}
            sx={{
                width: 'auto',
                height: '48px',
                minWidth: '122px',
                maxWidth: '203px',
                borderRadius: '44px',
                padding: '12px 20px',
                boxShadow: '0px 2px 8px 0px #1E07511F',
                opacity: '1', // Assuming 0px in the opacity was a typo
                backgroundColor: '#fff',
                color: '#1F1F2A', // Assuming the text color should be white for contrast
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                '&:hover': {
                    backgroundColor: '#F6F7FB', // Darker shade for hover effect
                },
                fontSize: '16px',
                fontWeight: 600,
                lineHeight: '24.8px',
                textTransform: 'none',
            }}
        >
            {children}
        </Button>
    );
};

const ReminderCard = ({ title, date, time }) => {
    return (
        <Card>
            <div style={{ display: 'flex', alignItems: 'center', gap: '8px', height: 86, padding: '16px'  }}>
                <div style={{ width: '24px', height: '24px', borderRadius: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <NotificationsNoneRoundedIcon  />
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '4px', flexGrow: 1, paddingLeft: 12 }}>
                    <Typography style={{ color: '#1F1F2A', fontSize: '16px', fontWeight: 600 }}>{title}</Typography>
                    <Typography style={{ color: '#7D7D97', fontSize: '14px', fontWeight: 500 }}>{`${time}, ${date}`}</Typography>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <IconButton>
                        <ChevronRightRoundedIcon />
                    </IconButton>
                </div>
            </div>
        </Card>
    );
};


const DiaryEntryForm = ({ initialValues, petId, onSubmit }) => {
    const navigate = useNavigate();
    const classes = useStyles();
    const [selectedCategories, setSelectedCategories] = useState(initialValues?.category || '');
    const [selectedImages, setSelectedImages] = useState([]);
    const [locationDrawerOpen, setLocationDrawerOpen] = useState(false);
    const [reminderDrawerOpen, setReminderDrawerOpen] = useState(false);

    const openReminderDrawer = () => {
        setReminderDrawerOpen(true);
    };

    const openLocationDrawer = () => {
        setLocationDrawerOpen(true);
    };
    const handleRemoveLocation = () => {
        formik.setFieldValue('location', '');
    };

    const handleRemoveReminder = () => {
        formik.setFieldValue('diary_items', {
            title: '',
            date: '',
            time: '',
            location: '',
            type: 'reminder',
        });
    };

    const formik = useFormik({
        initialValues: {
            date: initialValues?.date || null,
            description: initialValues?.description || '',
            category: initialValues?.category || '',
            photos: initialValues?.photos || null,
            location: initialValues?.location || null,
            diary_items: {
                title: initialValues?.diary_items?.title || '',
                date: initialValues?.diary_items?.date || new Date().toLocaleDateString('en-CA'), // YYYY-MM-DD
                time: initialValues?.diary_items?.time || '', // HH:MM
                location: initialValues?.diary_items?.location || '',
                type: 'reminder', // Default to reminder for now
            }
        },
        validationSchema: Yup.object({
            date: Yup.date().required(''),
            description: Yup.string().required(''),
            category: Yup.string().required(''),
        }),
        onSubmit: onSubmit,
    });

    useEffect(() => {
        if (initialValues?.photos) {
            setSelectedImages(Object.values(initialValues.photos));
        }
    }, [initialValues]);

    const handleCategoryChange = (category) => {
        setSelectedCategories(category);
        formik.setFieldValue('category', category);
    };

    const handleDiscard = () => {
        formik.resetForm();
        navigate(paths.petProfile(petId));
    };


    const handleAddPhotos = () => {
        // Trigger file input click
        document.getElementById('photoInput').click();
    };

    const handlePhotoChange = (event) => {
        const files = Array.from(event.target.files);
        const newImages = files.slice(0, 3 - selectedImages.length); // Limit to 3 total images

        // limit file size to less than 5MB
        const invalidFiles = newImages.filter((file) => file.size > 5 * 1024 * 1024);
        if (invalidFiles.length > 0) {
            toast.error('Image size exceeds 5MB');
            return;
        }
        
        setSelectedImages((prev) => {
            const updatedImages = [...prev, ...newImages];

            // Update Formik field value and mark it as touched
            formik.setFieldValue("photos", updatedImages);
            formik.setFieldTouched("photos", true);

            return updatedImages;
        });
    };


    const handleRemovePhoto = (index) => {
        setSelectedImages((prev) => prev.filter((_, i) => i !== index));
        formik.setFieldValue('photos', (prev) => { delete prev[index]; return prev; });
    };

    const getImagePreview = (image) => {
        if (typeof image === 'string') {
            return image;
        } else {
            return URL.createObjectURL(image);
        }
    };

    useEffect(() => {
        if (formik.isSubmitting && formik.errors) {
            const errorKeys = Object.keys(formik.errors);
            if (errorKeys.length > 0) {
                const firstErrorKey = errorKeys[0];
                const errorElement = document.querySelector(`[name="${firstErrorKey}"]`);
                if (errorElement) {
                    errorElement.scrollIntoView({ behavior: 'smooth' });
                }
            }
        }
    }, [formik.isSubmitting, formik.errors]);


    const handleSubmit = async () => {
        formik.setSubmitting(true);
        const newPhotos = {};

        try {
            // Upload each file to S3 and store the URL
            for (const file of selectedImages) {
                if (typeof file === 'string') {
                    newPhotos[file] = file;
                    continue;
                }

                try {
                    // Upload the image to S3
                    const formData = new FormData();
                    formData.append('file', file);

                    const api = new ApiClient();
                    const response = await api.post_form('cms/image/upload/', formData);
        
                    if (!response) {
                        toast.error('Failed to get image response');
                        return;
                    }

                    const s3Url = response?.file_url;
                    if (!s3Url) {
                        throw new Error('S3 URL not found');
                    }

                    const key = `${Date.now()}_${file.name}`;
                    newPhotos[key] = s3Url;

                } catch (error) {
                    toast.error(error.message, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            }

            // Update Formik's photos field
            formik.setFieldValue("photos", newPhotos, false);
            
            // Submit the form with updated photos
            let payload = {
                ...formik.values,
                photos: newPhotos,
            };

            if (isDiaryPopulated()) {
                // Parse diary_items to a list of objects
                const diary_items_list = [formik.values.diary_items];
                payload.diary_items = diary_items_list;
            }else{
                payload.diary_items = [];
            }

            await onSubmit(payload);
        } catch (error) {
            console.error("Error uploading images or submitting form:", error);
            toast.error(error.message || 'Error uploading images or submitting form');
        } finally {
            formik.setSubmitting(false);
        }
    };

    useEffect(() => {
        formik.setValues({
            ...formik.values,
            date: initialValues?.date || new Date().toLocaleDateString('en-CA'),
        });
        return () => {
            formik.resetForm();
        }
    }, []);

    const isDiaryPopulated = () => {
        const { diary_items } = formik.values;
        return diary_items.title !== '' && diary_items.date !== '' && diary_items.time !== '';
    };

    return (
        <div>
            <Header>
                <PetProfileFormHeader
                    title={initialValues?.id ? 'Edit diary entry' : 'New diary entry'}
                    petId={petId || null}
                    hasTabs={false}
                />
            </Header>
            <Container maxWidth="lg" sx={{ padding: "0 20px" }}>
                <form onSubmit={(e) => {
                    e.preventDefault();
                    handleSubmit();
                }}
                    className={classes.formContainer}>
                    <div style={{ width: "100%" }}>
                        <div className={classes.detailSection}>
                            <div className={classes.iconContainer}>
                                <Typography align="left" className={classes.iconText}>
                                    Category <span style={{ color: 'red' }}>*</span>
                                </Typography>
                            </div>
                            <div style={{
                                display: "flex", overflowX: 'scroll',
                                // hide scrollbar
                                '&::-webkit-scrollbar': {
                                    display: 'none',
                                },
                                msOverflowStyle: 'none',
                                scrollbarWidth: 'none',
                                gap: "8px",

                            }}>
                                {Object.entries(diaryEntryTypes).map(([category, name]) => (
                                    <Button
                                        key={category.toLowerCase()}
                                        value={category.toLowerCase()}
                                        variant="outlined"
                                        onClick={() => handleCategoryChange(category.toLowerCase())}
                                        sx={{
                                            width: "fit-content",
                                            minWidth: "fit-content",
                                            height: "40px",
                                            padding: "0px 16px 0px 16px",
                                            gap: "8px",
                                            borderRadius: "24px",
                                            border: "1px solid",
                                            color: "#1F1F2A",
                                            borderColor: selectedCategories === category.toLowerCase() ? "#7019FF" : "#E3E8FF",
                                            fontWeight: selectedCategories === category.toLowerCase() ? 600 : 500,
                                            color: selectedCategories === category.toLowerCase() ? "#7019FF" : "#7D7D97",
                                            fontSize: 16,
                                            lineHeight: "24.8px",
                                            textTransform: "none",
                                        }}
                                    >
                                        {name}
                                    </Button>
                                ))}
                            </div>
                            {formik.errors.category && (
                                <Typography color="error">{formik.errors.category}</Typography>
                            )}
                        </div>

                        <div className={classes.detailSection}>
                            <div className={classes.iconContainer}>
                                <Typography align="left" className={classes.iconText}>
                                    Date <span style={{ color: 'red' }}>*</span>
                                </Typography>
                            </div>
                            <ResponsiveDatePickers
                                name="date"
                                value={formik.values.date || new Date()}
                                onChange={(key, value) => {
                                    // Ensure the value is in the correct format, handling timezone
                                    const formattedValue = value ? new Date(value).toLocaleDateString('en-CA') : null;
                                    formik.setFieldValue(key, formattedValue);
                                }} error={formik.touched.date && Boolean(formik.errors.date)}
                                helperText={formik.touched.date && formik.errors.date}
                                ResponsiveDatePickers
                                disableFuture
                                format="dd MMM yyyy"
                                
                            />
                            {
                                formik.errors.date && (
                                    <Typography color="error">{formik.errors.date}</Typography>
                                )
                            }
                        </div>

                        <div className={classes.detailSection}>
                            <div className={classes.iconContainer}>
                                <Typography align="left" className={classes.iconText}>
                                    What happened? <span style={{ color: 'red' }}>*</span>
                                </Typography>
                            </div>
                            <TextField
                                name="description"
                                multiline
                                // border color on blur
                                // variant="outlined"
                                rows={4}
                                value={formik.values.description}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                // error={formik.touched.description && Boolean(formik.errors.description)}
                                // helperText={formik.touched.description && formik.errors.description}
                                className={classes.inputTextBox}
                                placeholder='e.g. Snowie got a jab and is feeling down today'
                                //fieldset border color on focus
                                InputProps={{
                                    sx: {
                                        '& fieldset': {
                                            borderColor: '#E3E8FF',
                                            borderWidth: '0px',
                                        },
                                        '&:hover fieldset': {
                                            borderColor: '#E3E8FF',
                                        },
                                        '&.Mui-focused fieldset': {
                                            borderColor: '#7019FF',
                                        },
                                    },
                                }}
                            />
                            {
                                formik.errors.description && (
                                    <Typography color="error">{formik.errors.description}</Typography>
                                )
                            }
                        </div>
                        {/* Photo Section */}
                        {/* Display selected images */}
                        {selectedImages.length > 0 && (
                            <div className={classes.detailSection}>
                                <div className={classes.iconContainer}>
                                    <Typography align="left" className={classes.iconText}>
                                        Photos
                                    </Typography>
                                </div>
                                <div className={classes.imageRow}>
                                    {selectedImages.map((image, index) => (
                                        <div key={index} className={classes.imageContainer}>
                                            <img
                                                src={getImagePreview(image)}
                                                alt={`Selected ${index}`}
                                                className={classes.imagePreview}
                                            />
                                            <IconButton
                                                className={classes.removeIcon}
                                                onClick={() => handleRemovePhoto(index)}
                                            >
                                                <CloseIcon />
                                            </IconButton>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}
                        {/* Location Section */}
                        {formik.values.location && (
                            <div className={classes.detailSection}>
                                <div className={classes.iconContainer}
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        width: '100%'
                                    }}>
                                    <Typography align="left" className={classes.iconText}>
                                        Location
                                    </Typography>
                                    <Button
                                        onClick={handleRemoveLocation}
                                        sx={{
                                            padding: 0,
                                            minWidth: 'auto',
                                            marginLeft: 'auto', // Push to end
                                            color: '#7019FF',
                                            '&:hover': {
                                                backgroundColor: 'transparent',
                                                opacity: 0.8,
                                            },
                                            fontSize: '14px',
                                            fontWeight: 600,
                                            lineHeight: '21.7px',
                                            textTransform: 'none',
                                            cursor: 'pointer'
                                        }}
                                    >
                                        Remove
                                    </Button>
                                </div>
                                <TextField
                                    name="location"
                                    variant="outlined"
                                    value={formik.values.location}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    className={classes.inputTextBox}
                                    placeholder='e.g. Vet clinic'
                                    InputProps={{
                                        startAdornment: <LocationOnOutlinedIcon
                                            sx={{
                                                color: '#7019FF',
                                                fontSize: '20px',
                                                marginLeft: '8px',
                                                marginRight: '8px',
                                            }}
                                        />,
                                    }}
                                />
                            </div>

                        )}
                        {/* Reminder Section */}
                        {isDiaryPopulated() && (
                            <div className={classes.detailSection}>
                                <div className={classes.iconContainer}
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        width: '100%'
                                    }}>
                                    <Typography align="left" className={classes.iconText}>
                                        Reminder
                                    </Typography>
                                    <Button
                                        onClick={handleRemoveReminder}
                                        sx={{
                                            padding: 0,
                                            minWidth: 'auto',
                                            marginLeft: 'auto', // Push to end
                                            color: '#7019FF',
                                            '&:hover': {
                                                backgroundColor: 'transparent',
                                                opacity: 0.8,
                                            },
                                            fontSize: '14px',
                                            fontWeight: 600,
                                            lineHeight: '21.7px',
                                            textTransform: 'none',
                                            cursor: 'pointer'
                                        }}
                                    >
                                        Remove
                                    </Button>
                                </div>
                                <ReminderCard {...formik.values.diary_items} />
                            </div>
                        )}

                        <div className={classes.detailSection} style={{
                            display: 'flex',
                            alignItems: 'center',
                            flexWrap: 'wrap',
                            gap: '8px',
                        }}>
                            {/* Photo expand button */}
                            {selectedImages.length < 3 && (
                                <>
                                    <ExpandButton onClick={handleAddPhotos}>
                                        Photos
                                    </ExpandButton>
                                    <input
                                        type="file"
                                        id="photoInput"
                                        multiple
                                        accept="image/*"
                                        style={{ display: 'none' }}
                                        onChange={handlePhotoChange}
                                    />
                                </>
                            )}
                            {!formik.values.location &&
                                <ExpandButton onClick={openLocationDrawer}>
                                    Location
                                </ExpandButton>
                            }
                            {!isDiaryPopulated() &&
                                <ExpandButton onClick={openReminderDrawer}>
                                    Reminder
                                </ExpandButton>
                            }
                        </div>

                        <div className={classes.buttonGroup}>
                            <Button
                                variant="outlined"
                                onClick={handleDiscard}
                                sx={{
                                    marginRight: "16px",
                                    width: "25%",
                                    padding: "0px 16px",
                                    gap: "8px",
                                    borderRadius: "8px",
                                    color: "#000",
                                    backgroundColor: "#fff",
                                    boxShadow: "0px 2px 8px 0px #1E07511F",
                                    border: "0px",
                                }}
                            >
                                {"Cancel"}
                            </Button>

                            <Button
                                variant="contained"
                                color="primary"
                                type="submit"
                                sx={{ width: "75%" }}
                                disabled={formik.isSubmitting || !formik.dirty || !formik.isValid}
                            >

                                {formik.isSubmitting ? <CircularProgress size={24} /> : "Save entry"}
                            </Button>
                        </div>
                    </div>
                </form>
            </Container>
            <LocationDrawer
                title="Search Locations"
                openDrawer={locationDrawerOpen}
                setOpenDrawer={setLocationDrawerOpen}
                setLocationField={(location) => { formik.setFieldValue('location', location) }}
            />
            <ReminderDrawer
                title="Add Reminder"
                openDrawer={reminderDrawerOpen}
                setOpenDrawer={setReminderDrawerOpen}
                formik={formik}
            />
        </div>
    );
};

export default DiaryEntryForm;