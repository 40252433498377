import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { TextField, Button, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import DropzoneImage from '../DropImage/DropzoneImage.jsx';
import EditableText from '../EditableText/EditableText.jsx';
import usePetParentStorage from '../../managers/parentManager.jsx';
import IconButton from '@mui/material/IconButton';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import InputAdornment from '@mui/material/InputAdornment';
import ResponsiveDatePickers from '../DatePicker/datePicker.jsx';
import dayjs from 'dayjs';
import CountryDropdown from '../Menus/CountryDropdown.jsx';

const useStyles = makeStyles((theme) => ({
    title: {
        '&.MuiTypography-root': {
            fontSize: '16px',
            fontWeight: 500,
            lineHeight: '19.5px',
            color: 'var(--Faux-Black, rgba(31, 31, 42, 1))',
            marginBottom: '8px',
        }
    },
    formContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: '100%',
        padding: '40px 0 100px 0px',
    },
    formItem: {
        marginBottom: '16px',
    },
    inputTextBox: {
        // padding: '16px',
        width: '100%',
        background: 'var(--Light-Grey, #F6F7FB)',
        borderRadius: '8px',
        '& .MuiOutlinedInput-root': {
            borderRadius: '8px',
            '& fieldset': {
                border: '1px solid #E3E8FF !important',
            },
        },
        '& .MuiInputBase-input': {
            fontSize: '16px',
            fontWeight: 500,
            lineHeight: '24.8px',
            textAlign: 'left',
            color: '#1F1F2A !important',
        },
        '& .MuiInputBase-input.Mui-disabled': {
            '-webkit-text-fill-color': '#1F1F2A !important', // Override the disabled color
            color: '#1F1F2A !important', // Ensure consistent color
        },

    },
    nonEditInputTextBox: {
        '& .MuiOutlinedInput-root': {
            borderRadius: '8px',
            '& fieldset': {
                border: '0px solid #E3E8FF !important',
            },
        },
    },
    detailSection: {
        marginBottom: '16px',
    },
    detailSectionTitle: {
        marginBottom: '24px',
    },
    titleSection: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
    },

    detailTitle: {
        "&.MuiTypography-root": {
            fontSize: '18px',
            fontWeight: 600,
            lineHeight: '21.94px',
            textAlign: 'left',
            color: '#1F1F2A',
        },
    },
    iconContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        width: '100%',
        paddingBottom: '8px',
        gap: '8px',
    },
    iconText: {
        '&.MuiTypography-root': {
            fontSize: '14px',
            fontWeight: 600,
            color: "#1F1F2A",
            lineHeight: '21.7px',
        }
    },
    buttonGroup: {
        // make the button group sticky at the bottom
        display: 'flex',
        position: 'fixed',
        bottom: 56,
        height: 88,
        left: '50%',
        transform: 'translateX(-50%)',
        zIndex: 1000,
        maxWidth: '600px', // Make it phone size only at 600px
        width: '100%',
        padding: "20px",
        background: "#fff",
        "& > button": {
            fontSize: "16px",
            fontWeight: 600,
            lineHeight: "19.5px",
            textTransform: "none",
        },
    },
    editIcon: {
        // position the edit icon at the top right corner of the parent container
        position: 'absolute',
        top: '96px',
        right: '0px',
        padding: '20px',
    },
}));

const AboutMeEditForm = () => {
    const classes = useStyles();
    const [isEditing, setIsEditing] = React.useState(false);

    const handleEditClick = () => {
        setIsEditing(!isEditing);
    }
    const {
        updatePetParent,
        getCurrentPetParent,
        updateParentByIdAPI
    } = usePetParentStorage('customer'); // 'parents' key for localStorage
    const [currentPetParent, setCurrentPetParent] = React.useState(getCurrentPetParent());

    // current logged in user
    React.useEffect(() => {
        formik.setValues({
            name: currentPetParent?.name || '',
            email: currentPetParent?.email || '',
            phone: currentPetParent?.phone || '',
            country_code: currentPetParent?.country_code || '+971',
            date_of_birth: currentPetParent?.date_of_birth || '',
            address: currentPetParent?.address || '',
            id_number: currentPetParent?.id_number || '',
            passport_number: currentPetParent?.passport_number || '',
            photo: currentPetParent?.photo || '',
        });
    }, [currentPetParent]);

    const onSubmit = async (values) => {
        if (values) {
            try {

                // Update via API or local storage
                await updateParentByIdAPI(currentPetParent.id, values);

                // Update local state and storage
                const updatedParent = { ...currentPetParent, ...values };
                setCurrentPetParent(updatedParent);
                updatePetParent(currentPetParent.id, updatedParent); // Sync with local storage
            } catch (error) {
                console.error("Failed to update parent", error);
            }
        }
        setIsEditing(false);
        formik.setSubmitting(false);
    };

    const formik = useFormik({
        initialValues: {
            name: currentPetParent?.name || '',
            email: currentPetParent?.email || '',
            country_code: currentPetParent?.country_code || '+971',
            phone: currentPetParent?.phone || '',
            date_of_birth: currentPetParent?.date_of_birth || '',
            address: currentPetParent?.address || '',
            id_number: currentPetParent?.id_number || '',
            passport_number: currentPetParent?.passport_number || '',
            photo: currentPetParent?.photo || null,
        },
        validationSchema: Yup.object({
            name: Yup.string().required('Required'),
            email: Yup.string().email('Invalid email address').required('Required'),
            phone: Yup.string()
                .matches(/^\+?[0-9]+$/, 'Invalid phone number')
                .optional(),
            date_of_birth: Yup.date().optional(),
            address: Yup.string().optional(),
            id_number: Yup.string().optional(),
            passport_number: Yup.string().optional(),
        }),
        onSubmit: onSubmit,
    });

    return (
        <form onSubmit={formik.handleSubmit} className={classes.formContainer}>
            {!isEditing && (
                <div className={classes.editIcon}>
                    <IconButton className={classes.icons} onClick={handleEditClick}>
                        <BorderColorOutlinedIcon sx={{ color: '#1F1F2A' }} />
                    </IconButton>
                </div>
            )}
            <div className={classes.detailSection}>
                <DropzoneImage formik={formik} isEditing={isEditing} />
            </div>

            {!isEditing ? (
                <div className={`${classes.detailSection} ${classes.titleSection}`}>
                    <EditableText
                        text={formik.values.name}
                        onSave={(value) => formik.setFieldValue("name", value)}
                        isEditing={isEditing}
                        setIsEditing={() => { formik.dirty.valueOf("name"); setIsEditing(true) }}
                        name="name"
                        clickable={false}
                        multiline={false}
                        className={classes.title}
                    />
                </div>
            ) : (
                <div className={classes.detailSection}>
                    <div className={classes.iconContainer}>
                        <Typography align="left" className={classes.iconText}>
                            Name
                        </Typography>
                    </div>
                    <TextField
                        name="name"
                        type="text"
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.name && Boolean(formik.errors.name)}
                        helperText={formik.touched.name && formik.errors.name}
                        className={isEditing ? `${classes.inputTextBox}` : `${classes.inputTextBox} ${classes.nonEditInputTextBox}`}
                        fullWidth
                        disabled={!isEditing}
                        placeholder={isEditing ? "Enter Name" : "Not added yet"}
                    />
                </div>

            )}
            <div style={{ marginBottom: 32 }}>
                <div className={classes.iconContainer}>
                    <Typography align="left" className={classes.iconText}>
                        Email Address
                    </Typography>
                </div>
                <p style={{
                    margin: '0px',
                }}>
                    <span
                        style={{
                            color: '#7D7D97',
                            display: 'block',
                            // marginTop: '-8px',
                        }}
                    >
                        {formik.values.email}
                    </span>
                </p>
            </div>
            <div className={classes.detailSection}>
                <div className={classes.iconContainer}>
                    <Typography align="left" className={classes.iconText}>
                        Contact Number
                    </Typography>
                </div>
                {isEditing ? (
                    <div style={{ display: 'flex', alignItems: 'center', gap: '8px', justifyContent: 'flex-start' }}>
                        <CountryDropdown
                            setCountryCode={formik.setFieldValue}
                            countryCode={formik.values.country_code}
                        />
                        <TextField
                            name="phone"
                            type="text"
                            value={formik.values.phone}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.phone && Boolean(formik.errors.phone)}
                            helperText={formik.touched.phone && formik.errors.phone}
                            className={isEditing ? `${classes.inputTextBox}` : `${classes.inputTextBox} ${classes.nonEditInputTextBox}`}
                            placeholder={isEditing ? "Enter phone number" : "Not added yet"}
                            fullWidth
                            disabled={!isEditing}
                            // whatsapp icon
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position='start' >
                                        <Typography
                                            sx={{
                                                fontSize: '16px',
                                                fontWeight: 500,
                                                lineHeight: '24.8px',
                                                textAlign: 'left',
                                                color: '#1F1F2A !important',
                                            }}
                                        >{formik.values.country_code}</Typography>
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <InputAdornment position='end' >
                                        <img
                                            style={{ width: '24px', height: '24px' }}
                                            src={`${process.env.PUBLIC_URL}/socialIcons/whatsapp.svg`}
                                            alt="whatsapp icon"
                                        />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </div>
                ) : (
                    <TextField
                        name="phone"
                        type="text"
                        value={formik.values.phone ? `${formik.values.country_code}${formik.values.phone}` : ''}
                        className={`${classes.inputTextBox} ${classes.nonEditInputTextBox}`}
                        placeholder={"Not added yet"}
                        fullWidth
                        disabled
                        // whatsapp icon
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position='start' >
                                    <img
                                        style={{ width: '24px', height: '24px' }}
                                        src={`${process.env.PUBLIC_URL}/socialIcons/whatsapp.svg`}
                                        alt="whatsapp icon"
                                    />
                                </InputAdornment>
                            ),
                        }}
                    />
                )}
            </div>
            <div className={classes.detailSection}>
                <div className={classes.iconContainer}>
                    <Typography align="left" className={classes.iconText}>
                        Date of Birth
                    </Typography>
                </div>
                <ResponsiveDatePickers
                    name="date_of_birth"
                    value={formik.values.date_of_birth || null}
                    onChange={(name, value) => {
                        // Ensure the value is in the correct format, handling timezone
                        const formattedValue = value ? new Date(value).toLocaleDateString('en-CA') : null;
                        formik.setFieldValue(name, formattedValue);
                    }}
                    error={formik.touched.date_of_birth && Boolean(formik.errors.date_of_birth)}
                    helperText={formik.touched.date_of_birth && formik.errors.date_of_birth}
                    disableFuture
                    format="dd/MM/yyyy"
                    className={isEditing ? `${classes.inputTextBox}` : `${classes.inputTextBox} ${classes.nonEditInputTextBox}`}
                    maxDate={dayjs().subtract(10, 'year').toISOString()}
                    disable={!isEditing}
                    placeholder={isEditing ? "Enter date of birth" : "Not added yet"}
                />
            </div>
            <div className={classes.detailSection}>
                <div className={classes.iconContainer}>
                    <Typography align="left" className={classes.iconText}>
                        Address
                    </Typography>
                </div>
                <TextField
                    name="address"
                    type="text"
                    value={formik.values.address}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.address && Boolean(formik.errors.address)}
                    helperText={formik.touched.address && formik.errors.address}
                    className={isEditing ? `${classes.inputTextBox}` : `${classes.inputTextBox} ${classes.nonEditInputTextBox}`}
                    fullWidth
                    disabled={!isEditing}
                    placeholder={isEditing ? "Enter address" : "Not added yet"}
                    multiline
                    rows={formik.values.address.length > 50 ? 3 : 1}
                />
            </div>
            <div className={classes.detailSection}>
                <div className={classes.iconContainer}>
                    <Typography align="left" className={classes.iconText}>
                        ID Number
                    </Typography>
                </div>
                <TextField
                    name="id_number"
                    type="text"
                    value={formik.values.id_number}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.id_number && Boolean(formik.errors.id_number)}
                    helperText={formik.touched.id_number && formik.errors.id_number}
                    className={isEditing ? `${classes.inputTextBox}` : `${classes.inputTextBox} ${classes.nonEditInputTextBox}`}
                    fullWidth
                    disabled={!isEditing}
                    placeholder={isEditing ? "Enter ID number" : "Not added yet"}
                />
            </div>
            <div className={classes.detailSection}>
                <div className={classes.iconContainer}>
                    <Typography align="left" className={classes.iconText}>
                        Passport Number
                    </Typography>
                </div>
                <TextField
                    name="passport_number"
                    type="text"
                    value={formik.values.passport_number}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.passport_number && Boolean(formik.errors.passport_number)}
                    helperText={formik.touched.passport_number && formik.errors.passport_number}
                    className={isEditing ? `${classes.inputTextBox}` : `${classes.inputTextBox} ${classes.nonEditInputTextBox}`}
                    fullWidth
                    disabled={!isEditing}
                    placeholder={isEditing ? "Enter passport number" : "Not added yet"}
                />
            </div>
            {isEditing && (
                <div className={classes.buttonGroup}>
                    <Button
                        variant="outlined"
                        onClick={() => setIsEditing(false)}
                        sx={{
                            marginRight: "16px",
                            width: "25%",
                            padding: "0px 16px",
                            gap: "8px",
                            borderRadius: "8px",
                            color: "#000",
                            backgroundColor: "#fff",
                            boxShadow: "0px 2px 8px 0px #1E07511F",
                            border: "0px",
                        }}
                    >
                        {Object.keys(formik.values).length > 0 ? "Discard" : "Cancel"}
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        sx={{ width: "75%" }}
                        disabled={formik.isSubmitting || !formik.dirty}
                    >
                        Save Changes
                    </Button>
                </div>
            )}
        </form>
    );
};

export default AboutMeEditForm;