import React from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Typography } from '@mui/material';
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded';


const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        cursor: 'pointer',
        height: 80
    },
    selector: {
        display: 'flex',
        alignItems: 'center',
        padding: '12px 16px',
        background: '#FFFFFF',
        borderRadius: '12px',
        boxShadow: '0px 2px 8px 0px #1E07511F',
        gap: '12px',
        minHeight: '64px',
    },
    placeholder: {
        display: 'flex',
        alignItems: 'center',
        gap: '12px',
    },
    placeholderIcon: {
        width: '48px',
        height: '48px',
        borderRadius: '8px',
    },
    parentImage: {
        width: '40px',
        height: '40px',
        borderRadius: '8px',
        objectFit: 'cover',
    },
    text: {
        flex: 1,
        color: '#1F1F2A',
        fontSize: '16px',
        fontWeight: 500,
        lineHeight: '24.8px',
    },
    arrowIcon: {
        color: '#7D7D97',
        fontSize: '24px',
    },
}));

const ParentSelector = ({ selectedParent, onSelect, disabled }) => {
    const classes = useStyles();

    
    const PlaceholderIcon = () => (
        <div>
            <img className={classes.placeholderIcon} src={`${process.env.PUBLIC_URL}/logo-gray.png`} alt='logo' />
        </div>
    );

    return (
        <Box className={classes.container} onClick={disabled ? null : onSelect} disabled={disabled}>
            <Box className={classes.selector}>
                {selectedParent ? (
                    <>
                        <img
                            src={selectedParent.photo || `${process.env.PUBLIC_URL}/logo-gray.png`}
                            alt={selectedParent.name}
                            className={classes.parentImage}
                        />
                        <Typography className={classes.text}>
                            {selectedParent.name}
                        </Typography>
                    </>
                ) : (
                    <>
                        <PlaceholderIcon />
                        <Typography className={classes.text}>
                            Unknown parent
                        </Typography>
                    </>
                )}
                {!disabled && <KeyboardArrowRightRoundedIcon className={classes.arrowIcon} />}
            </Box>
        </Box>
    );
};

export default ParentSelector;