import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Button } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import { ReactComponent as ContactIcon } from "../Footer/footerIcons/contact.svg";


const useStyles = makeStyles((theme) => ({
    buttonGroup: {
        // make the button group sticky at the bottom
        display: 'flex',
        position: 'fixed',
        bottom: '0',
        height: 80,
        left: '50%',
        transform: 'translateX(-50%)',
        zIndex: 1000,
        maxWidth: '600px', // Make it phone size only at 600px
        width: '100%',
        padding: "16px 20px",
        gap: '16px',
        background: "#fff",
        boxShadow: "2px 0px 12px 0px #00000026",
        "& > button": {
            fontSize: "14px",
            fontWeight: 600,
            lineHeight: "21.7px",
            textTransform: "none",
        },
    },
    icon: {
        width: 17,
        height: 17,
        fill: "#fff",
        // margin: "4px",
    },
}));

const BottomContactButtons = ({ item, whatsappMessage}) => {
    const classes = useStyles();
    const [whatsappUrl, setWhatsappUrl] = React.useState("#");
    useEffect(() => {
        const getWhatsappUrl = async () => {
            const message = whatsappMessage;
            const encodedMessage = encodeURIComponent(message);
            const whatsappLink = `https://wa.me/${item.whatsapp}?text=${encodedMessage}`;
            setWhatsappUrl(whatsappLink);
        };
        if (item.whatsapp) getWhatsappUrl();
    }, [item.whatsapp]);

    return (
        <div className={classes.buttonGroup}>
            <Button
                variant="contained"
                color="primary"

                onClick={
                    // Handle call button click
                    () => {
                        window.location.href = `tel:${item.phone}`;
                    }
                }
                sx={{
                    width: "50%",
                    padding: "0px 16px",
                    borderRadius: "8px",

                }}
            >
                <InputAdornment position="start">
                    <ContactIcon className={classes.icon} />
                </InputAdornment>
                {"Call"}
            </Button>

            <Button
                variant="outlined"
                sx={{
                    width: "50%",
                    padding: "0px 13px",
                    borderRadius: "8px",
                    color: "#000",
                    backgroundColor: "#fff",
                    boxShadow: "0px 2px 8px 0px #1E07511F",
                    border: "0px",
                }}
                onClick={
                    // Handle whatsapp button click (open whatsapp with message in new tab)
                    () => {
                        window.open(whatsappUrl, "_blank");
                    }
                }
            >
                <InputAdornment position="start">
                    <img
                        style={{ width: '24px', height: '24px' }}
                        src={`${process.env.PUBLIC_URL}/socialIcons/whatsapp.svg`}
                    />
                </InputAdornment>
                {"Whatsapp"}
            </Button>
        </div>
    );
};

export default BottomContactButtons;